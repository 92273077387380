import clsx from 'clsx';
import { FC, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'debounce';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { btnSize, btnType, Button } from '@confidant-health/lib/ui/atoms/button';
import { IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
import { colors } from '@confidant-health/lib/colors';
import { Input, inputSize, inputType } from '@confidant-health/lib/ui/atoms/input';
import Loader, { LoaderEnums } from '@confidant-health/lib/ui/templates/loader';
import { INote } from '@confidant-health/lib/ui/templates/note-card';
import { fontWeight, Text, textLevel } from '@confidant-health/lib/ui/atoms/typography';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { conversationActionCreators } from 'redux/modules/conversation';
import { mapMessageToGiftedChat } from 'redux/modules/conversation/saga';
import { profileActionCreators } from 'redux/modules/profile';
import { showSnackbar } from 'redux/modules/snackbar';
import { getAuth } from 'redux/modules/auth/selectors';
import { getConversation, getMessages } from 'redux/modules/conversation/selectors';
import { getProfile } from 'redux/modules/profile/selectors';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { IConnection, IUserGroup } from 'redux/modules/profile/types';
import { getContactNotesCountByPatient, getMemberNotes } from 'services/member/member.service';
import { getNextAppointment } from 'services/appointment/appointment.service';
import * as MemberService from 'services/member/member.service';
import { BaseLayout } from 'layouts/base';
import CreateConnectionModal from 'layouts/base/CreateConnectionModal';
import history from 'utils/history';
import { uniqueConnections } from 'utils/CommonUtils';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import { getUserTimeZone } from 'utils/dayjs';
import { fetchMessagesFromOldApp } from 'services/conversation/conversation.service';
import { getUsersSummary } from 'services/profile/profile.service';
import { getSocketState } from 'redux/modules/socket/selectors';
import { Icons } from '@confidant-health/lib/icons';
import { getProviderSchedule } from 'services/schedule/schedule.service';
import AddNoteDrawer from '../member-detail/components/Notes/AddNoteDrawer/AddNoteDrawer';
import PriorityEmpty from '../member-detail/components/PriorityEmpty';
import UpdateVisitFrequency from '../member-detail/components/CareTeam/edit';
import NoteDrawer from '../member-detail/components/Notes/NoteDrawer';
import { ScheduleAppointment } from './schedule-appointment/ScheduleAppointment';
import { MessageInput } from './MessageInput';
import SideUserCard from './SideUserCard';
import CareTeamMembersDrawer from './CareTeamMembersDrawer';
import useContainerMediaQuery, { SizeTo } from './useContainerMediaQuery';
import { AVATAR_COLOR_ARRAY, CONNECTIONS_TYPES } from '../../../constants/CommonConstants';
import { uuid4 } from '../../../lib/sendbird';
import { tabSelectOptions, tabSelectOptionsAdmin, tabSelectOptionsCareNavigator } from './Chats.constant';
import { ConnectionByTypeT, SideUserDataT } from './Chats.types';
import { useStyles } from './Chats.styles';
import { MessageThread } from './MessageThread';
import { UpdatesModal } from './updates-modal';
import ChatHeader from './ChatHeader';
import NewUserChatCard from './NewUserChatCard';
import { SearchMenu } from './SearchMenu';

const Chats: FC = memo(() => {
  // Hooks initialisation
  const classes = useStyles({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const location: any = useLocation();
  const isMounted = useRef(false);
  const containerRef = useRef(null);
  const inputRef2 = useRef<HTMLInputElement>(null);
  const [setRef, hideNextAppointment] = useContainerMediaQuery(710, SizeTo.DOWN_TO);
  const [setRef2, hideVisitFrequency] = useContainerMediaQuery(460, SizeTo.DOWN_TO);
  const [setRef3, hideDOB] = useContainerMediaQuery(310, SizeTo.DOWN_TO);
  const elementRef = useRef<HTMLDivElement>(null);

  // Global state selectors
  const { profile, userGroups, groupsLoading, chats, demographicDetails, providerDetails } =
    useSelector(getProfile);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { isAdmin, meta } = useSelector(getAuth);
  const messages = useSelector(getMessages);
  const {
    isLoading,
    errorMsg,
    channelUrl,
    isLoadedAllMessages,
    chats: { channels },
    channelMembers,
    recentMessageSend,
  } = useSelector(getConversation);
  const { onlineUsers } = useSelector(getSocketState);
  // Variables
  const isDelete = true;
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  // Component states
  const [chatConnections, setChatConnections] = useState([] as any[]);
  const [isLoadingChatConnections, setIsLoadingChatConnections] = useState(true);
  const [connectionsList, setConnectionsList] = useState([] as any[]);
  const [careTeamDetail, setCareTeamDetail] = useState([]);
  const [selectedContact, setSelectedContact] = useState<SideUserDataT | null>(null);
  const [chatType, setChatType] = useState(
    meta.authority === 'BILLING_SUPPORT' || meta.authority === 'ADMIN'
      ? 'members'
      : params.get('type') || 'all',
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [requestAppointmentDialog, showRequestAppointmentDialog] = useState(false);
  const [requestAppointmentParams, setRequestAppointmentParams] = useState(null);
  const [openInstantAppointmentDrawer, setOpenInstantAppointmentDrawer] = useState<boolean>(false);
  const [openVisitFrequencyDrawer, setOpenVisitFrequencyDrawer] = useState<boolean>(false);
  const [, setNotes] = useState([]);
  const [, setTotalRecords] = useState(0);
  const [, setCountsByType] = useState([]);
  const [, setCountsByStatus] = useState([]);
  const [, setTotalNotes] = useState(0);
  const [selectedNote, setSelectedNote] = useState<INote | null>(null);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [, setOpen] = useState(false);
  const [showLatestBtn, setShowLatestBtn] = useState(false);
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);
  const [memberId, setMemberId] = useState(null);
  const [isCreateConnection, setIsCreateConnection] = useState(false);
  const [searchContactLoading, setSearchContactLoading] = useState(false);
  const [messageThread, setMessageThread] = useState({
    messageId: 0,
    message: '',
    nickName: '',
    colorCode: '',
    userId: '',
    name: '',
    avatar: '',
    thumbnail: '',
    createdDate: '',
    fileMeta: {
      type: '',
      url: '',
    },
  });
  const [showTodoDialog, setShowTodoDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [frequencyDataObj, setFrequencyDataObj] = useState({
    userId: profile.providerId,
    name: profile.fullName,
    visitFrequency: demographicDetails?.visitFrequency /* item.visitFrequency */,
  });
  const [chatCounts, setChatCounts] = useState({
    members: 0,
    providers: 0,
    careTeamMembers: 0,
    groups: 0,
    careNavigator: 0,
  });
  const [InstantAppointmentObj, setInstantAppointmentObj] = useState({
    provider: {
      id: profile?.providerId,
    },
    member: {
      id: memberId,
    },
    time: {
      start: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      end: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    },
  });
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [openCareTeamDrawer, setOpenCareTeamDrawer] = useState(false);
  const [, setShowCareTeamJoinCall] = useState(false);
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);
  // Loading Chat history states
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState(true);
  const [showLoadingMoreMessages, setShowLoadingMoreMessages] = useState(false);
  const [allOldMessagesFetched, setAllOldMessagesFetched] = useState(false);
  const [opportunityVisible, setOpportunityVisible] = useState(false);
  const [providerAvailability, setProviderAvailability] = useState({
    noOfDays: 0,
    activeDay: [],
    nextAvailability: null,
  });
  const [automatedSchedulingData, setAutomatedSchedulingData] = useState(null);

  const getProviderScheduleCall = async contact => {
    if (contact.type === CONNECTIONS_TYPES.PRACTITIONER) {
      const queryParams = {};
      const { data } = await getProviderSchedule(contact.connectionId, queryParams);
      const activeDays = new Set(data.availability.filter(entry => entry.active).map(entry => entry.day));
      const activeDaysArray = Array.from(activeDays);
      setProviderAvailability({
        noOfDays: activeDays.size,
        activeDay: activeDaysArray,
        nextAvailability: data.nextAvailableSlot.start,
      });
    }
  };

  // useEffect hooks
  useEffect(() => {
    if ((location?.state && location?.state?.tabType && location?.state?.chatId) || id) {
      const connectionId = location?.state?.chatId || id;
      const connection = getConnectionDetailsByUserId(connectionId);
      const tabChatType =
        location?.state?.tabType || connection?.type ? `${getChatTabType(connection?.type)}s` : 'all';
      const conversationList = getConversationsBySelectedChatType(tabChatType);

      if (connectionId) {
        let chatFound;
        if (tabChatType === 'groups') {
          chatFound = conversationList?.find(item => item?.channelUrl === `${connectionId}`);
        } else {
          chatFound = conversationList?.find(item => item?.connectionId === connectionId);
        }
        if (chatFound) {
          setSelectedContact(chatFound);
        }
      }
      if (tabChatType) {
        setChatType(tabChatType);
      }
    }
  }, []);

  useEffect(() => {
    if (!isAdmin) {
      dispatch(profileActionCreators.fetchUserGroups(profile?.providerId));

      const hasSeenUpdate = localStorage.getItem('hasSeenUpdate');

      // Show the modal if the user has not seen the update
      if (!hasSeenUpdate) setShowUpdatesModal(true);
    }
    dispatch(conversationActionCreators.fetchAllTodo());
    dispatch(conversationActionCreators.fetchUnreadChannels());
    isMounted.current = true;
    dispatch(profileActionCreators.clearChatSearch());
  }, []);

  useEffect(() => {
    setChatConnections(
      chats?.careNavigator?.connections?.length > 0
        ? [
            ...chats.members?.connections,
            ...chats.providers?.connections,
            ...chats.careTeam?.connections,
            ...chats?.careNavigator?.connections,
            ...userGroups,
          ]
        : [
            ...chats.members?.connections,
            ...chats.providers?.connections,
            ...chats.careTeam?.connections,
            ...userGroups,
          ],
    );
  }, [chats]);

  useEffect(() => {
    const searchKey = searchQuery?.trim()?.toLowerCase();
    setSearchContactLoading(
      searchKey?.length > 0 && chats?.searchResults?.isLoading && chatType !== 'groups',
    );
  }, [searchQuery, chats?.searchResults]);

  useEffect(() => {
    setSearchContactLoading(true);
  }, [searchQuery]);

  /**
   * @Name useEffect
   * @description This method is used to count chat
   */
  useEffect(() => {
    if (chatConnections?.length > 0) {
      const getUpdatedConnectionOnChannelFound = (foundChannel: any, item) => {
        if (foundChannel) {
          const lastMessage = getLastMessageFromChannel(foundChannel, item);
          return {
            ...item,
            lastMessage: lastMessage.message !== '' ? lastMessage.message : item.lastMessage,
            lastMessageTimestampUnix:
              lastMessage.timestamp !== '' ? lastMessage.timestamp : item.lastMessageTimestampUnix,
            unreadMessageCount: foundChannel.unreadMessageCount,
          };
        }
        return item;
      };
      const newCount = {
        members: 0,
        providers: 0,
        careTeamMembers: 0,
        groups: 0,
        careNavigator: 0,
        all: 0,
      };
      const connections = getChatConnectionsByChatType(
        chats,
        userGroups,
        chats.careTeam?.connections?.length > 0 && chats.careNavigator?.connections?.length > 0
          ? [
              CONNECTIONS_TYPES.PATIENT,
              CONNECTIONS_TYPES.PRACTITIONER,
              CONNECTIONS_TYPES.CARE_TEAM,
              CONNECTIONS_TYPES.CARE_NAVIGATOR,
              CONNECTIONS_TYPES.CHAT_GROUP,
              'ALL',
            ]
          : chats.careTeam?.connections?.length > 0
          ? [
              CONNECTIONS_TYPES.PATIENT,
              CONNECTIONS_TYPES.PRACTITIONER,
              CONNECTIONS_TYPES.CARE_TEAM,
              CONNECTIONS_TYPES.CHAT_GROUP,
              'ALL',
            ]
          : chats.careNavigator?.connections?.length > 0
          ? [
              CONNECTIONS_TYPES.PATIENT,
              CONNECTIONS_TYPES.PRACTITIONER,
              CONNECTIONS_TYPES.CARE_NAVIGATOR,
              CONNECTIONS_TYPES.CHAT_GROUP,
            ]
          : [CONNECTIONS_TYPES.PATIENT, CONNECTIONS_TYPES.PRACTITIONER, CONNECTIONS_TYPES.CHAT_GROUP],
      ) as any[];

      const searchKey = searchQuery?.trim()?.toLowerCase();
      const sortedConnections = sortConnections(
        [
          ...uniqueConnections(connections.filter(item => item.type === CONNECTIONS_TYPES.CARE_TEAM)),
          ...uniqueConnections(connections.filter(item => item.type !== CONNECTIONS_TYPES.CARE_TEAM)),
        ]
          .map(item => {
            const foundChannel = channels?.find(channel => channel.url === item.channelUrl);
            if (foundChannel && foundChannel.unreadMessageCount > 0) {
              if (item.type === CONNECTIONS_TYPES.PATIENT) {
                newCount.members += 1;
              } else if (item.type === CONNECTIONS_TYPES.CHAT_GROUP) {
                newCount.groups += 1;
              } else if (item.type === CONNECTIONS_TYPES.CARE_TEAM) {
                newCount.careTeamMembers += 1;
              } else if (item.type === CONNECTIONS_TYPES.CARE_NAVIGATOR) {
                newCount.careNavigator += 1;
              } else {
                newCount.providers += 1;
              }
            }
            if (searchKey?.length && searchQuery.length > 0) {
              if (
                item.nickName?.toLowerCase().includes(searchKey) ||
                item.fullName?.toLowerCase().includes(searchKey) ||
                `${item?.firstName ?? ''} ${item?.lastName ?? ''}`?.toLowerCase().includes(searchKey)
              ) {
                return getUpdatedConnectionOnChannelFound(foundChannel, item);
              }
              return null;
            }
            switch (chatType) {
              case 'members':
                return item.type === CONNECTIONS_TYPES.PATIENT
                  ? getUpdatedConnectionOnChannelFound(foundChannel, item)
                  : null;
              case 'providers':
                return item.type === CONNECTIONS_TYPES.PRACTITIONER
                  ? getUpdatedConnectionOnChannelFound(foundChannel, item)
                  : null;
              case 'groups':
                return item.type === CONNECTIONS_TYPES.CHAT_GROUP
                  ? getUpdatedConnectionOnChannelFound(foundChannel, item)
                  : null;
              case 'care_team':
                return item.type === CONNECTIONS_TYPES.CARE_TEAM
                  ? getUpdatedConnectionOnChannelFound(foundChannel, item)
                  : null;
              case 'care_navigator':
                return item.type === CONNECTIONS_TYPES.CARE_NAVIGATOR
                  ? getUpdatedConnectionOnChannelFound(foundChannel, item)
                  : null;
              default:
                return getUpdatedConnectionOnChannelFound(foundChannel, item);
            }
          })
          ?.filter(item => item),
      );
      setChatCounts(newCount);
      if (searchKey?.length && searchQuery.length > 0 && chatType !== 'groups') {
        setConnectionsList(
          sortConnections(
            chats.searchResults.connections?.map(item => {
              const foundChannel = channels?.find(channel => channel.url === item.channelUrl);
              return getUpdatedConnectionOnChannelFound(foundChannel, item);
            }),
          ),
        );
        chats.searchResults.connections?.length > 0 || connectionsList?.length > 0
          ? onChangeLoading(false)
          : onChangeLoading(
              chats.careTeam.isLoading ||
                chats.members.isLoading ||
                chats.providers.isLoading ||
                chats.careNavigator.isLoading ||
                groupsLoading,
            );
        return;
      }
      setConnectionsList(sortedConnections);
      sortedConnections?.length > 0 || connectionsList?.length > 0
        ? onChangeLoading(false)
        : onChangeLoading(
            chats.careTeam.isLoading ||
              chats.members.isLoading ||
              chats.providers.isLoading ||
              chats.careNavigator.isLoading ||
              groupsLoading,
          );
    }
  }, [channels, chatConnections, chatType, onlineUsers]);

  const getMessageIdFromUrl = () => {
    const url = new URL(window.location.href);
    const messageId = url.hash ? url.hash.substring(1) : null;
    return messageId;
  };

  const updateUrlWithMessageId = (contact, type, mId) => {
    const baseUrl = location.pathname.includes('provider')
      ? `/provider/chats?userId=${contact.connectionId}&type=${type}&isDirectCall=${
          selectedContact?.type === CONNECTIONS_TYPES.CARE_TEAM ||
          selectedContact?.type === CONNECTIONS_TYPES.CARE_NAVIGATOR
        }`
      : `/admin/chats?userId=${contact.connectionId}&type=${type}&isDirectCall=${
          selectedContact?.type === CONNECTIONS_TYPES.CARE_TEAM ||
          selectedContact?.type === CONNECTIONS_TYPES.CARE_NAVIGATOR
        }`;

    if (mId) {
      history.replace(`${baseUrl}#${mId}`);
    } else {
      history.replace(`${baseUrl}`);
    }
  };

  /**
   * @Name useLayoutEffect
   * @description This method is used to init chat
   */
  useLayoutEffect(() => {
    if (selectedContact) {
      const messageIdFromUrl = getMessageIdFromUrl();
      updateUrlWithMessageId(selectedContact, chatType, messageIdFromUrl);
      dispatch(
        conversationActionCreators.initChat({
          payload: {
            channelUrl: selectedContact?.channelUrl,
            connection: {
              ...selectedContact,
              messages: selectedContact?.messages || [],
              nickname: selectedContact?.nickName,
            },
            currentUser: {
              userId: profile?.providerId,
              nickname: profile?.fullName,
            },
          },
        }),
      );
    }
  }, [selectedContact]);

  useEffect(() => {
    updatingSelectedContact();
  }, [chatType, searchQuery, chatConnections]);

  useEffect(() => {
    if (searchQuery !== '') {
      setSearchQuery('');
      dispatch(profileActionCreators.clearChatSearch());
      setSearchContactLoading(true);
    }
  }, [chatType]);

  useEffect(() => {
    if (chatType !== 'groups') {
      if (searchQuery && searchQuery.trim() !== '') {
        dispatch(profileActionCreators.searchInChats({ type: chatType, search: searchQuery }));
      } else {
        dispatch(profileActionCreators.clearChatSearch());
        setSearchContactLoading(true);
      }
    }
  }, [searchQuery]);

  useEffect(() => {
    setShowLoadingMoreMessages(false);
    if (isLoadedAllMessages && allOldMessagesFetched) {
      setShowLoadMoreBtn(false);
    } else {
      setShowLoadMoreBtn(true);
    }
  }, [messages, allOldMessagesFetched]);

  useEffect(() => {
    if (location.search === '' && !!selectedContact) {
      setSelectedContact(null);
    }
  }, [location.search]);

  useEffect(() => {
    if (!selectedContact) {
      setMessageThread({
        messageId: 0,
        message: '',
        nickName: '',
        colorCode: '',
        userId: '',
        name: '',
        avatar: '',
        thumbnail: '',
        createdDate: '',
        fileMeta: {
          type: '',
          url: '',
        },
      });
    } else {
      void getProviderScheduleCall(selectedContact);
    }
  }, [selectedContact]);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const element = containerRef.current;
      element.scroll({
        top: element.scrollHeight,
        behavior: 'smooth',
        left: 0,
      });
    }
  }, [containerRef, selectedContact, messages?.length]);

  useEffect(() => {
    setFrequencyDataObj({
      userId: profile.providerId,
      name: profile.fullName,
      visitFrequency: demographicDetails?.visitFrequency /* item.visitFrequency */,
    });
  }, [demographicDetails]);

  useEffect(() => {
    if (memberId !== null && memberId !== undefined && !memberId.includes('sendbird')) {
      dispatch(profileActionCreators.fetchDemographicDetails(memberId));
      // if (!isAdmin) {
      setNextAppointment(null);
      void getNextAppointment(memberId).then(({ data }) => {
        setNextAppointment(data.appointment || null);
      });
      // }
    }
  }, [memberId]);

  useEffect(() => {
    if (
      selectedContact &&
      (selectedContact.type === CONNECTIONS_TYPES.CARE_TEAM ||
        selectedContact.type === CONNECTIONS_TYPES.CHAT_GROUP)
    ) {
      const memberIds = [];
      const providerIds = [];
      channelMembers.forEach(item => {
        if (item.userId !== providerDetails.providerId) {
          if (item.metaData.type === 'PATIENT') memberIds.push(item.userId);
          providerIds.push(item.userId);
        }
      });

      getUsersSummary({ memberIds, providerIds })
        .then(res => {
          const users = [
            ...res.data?.memberDetailList,
            ...res.data?.providerDetailList,
            ...res.data?.adminDetailsList,
          ];
          const newChannelMembers = [];
          users.forEach(user => {
            const foundUser = channelMembers.find(member => member.userId === user.userAccountId);
            if (foundUser) {
              newChannelMembers.push({ ...foundUser, ...user });
            }
          });
          setCareTeamDetail(newChannelMembers?.filter(item => item.userId !== selectedContact.connectionId));
        })
        .catch(err => console.log({ err }));
    }
  }, [selectedContact, channelMembers]);

  // Callbacks
  const onChangeSearchKey = debounce((searchKey: string) => {
    const newValue = searchKey;
    setSearchQuery(newValue);
  }, 500);

  const fetchNotes = useCallback(async () => {
    try {
      const { data } = await getMemberNotes({ userId: memberId });
      const listSorted = data.patientContactNotes;
      setNotes(listSorted);
      setTotalRecords(data.totalRecords);
      getContactNotesCountByPatient({ patientId: memberId })
        .then(res => {
          setTotalNotes(res.data?.ContactNotesCountByType?.totalNotesCount || 0);
          setCountsByType(getFormatedCountsByNoteTypes(res.data?.ContactNotesCountByType));
          setCountsByStatus(getFormatedCountsByNotesStatus(res.data?.ContactNotesCountByStatus));
        })
        .catch(err => console.log({ err }));
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [memberId]);

  const onScrollElement = useCallback(
    debounce(function () {
      if (containerRef?.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

        const scrollTopNum = Number(scrollTop);
        const clientHeightNum = Number(clientHeight);
        const scrollHeightNum = Number(scrollHeight);

        const isAtBottom = scrollHeightNum - (scrollTopNum + clientHeightNum) < 5; // Use a small threshold
        setShowLatestBtn(!isAtBottom);
      }
    }, 50),
    [containerRef],
  );

  const onChangeLoading = debounce((loadingState: boolean) => {
    setIsLoadingChatConnections(loadingState);
    setSearchContactLoading(loadingState);
  }, 3000);

  const refetchNotes = useCallback(() => {
    void fetchNotes();
  }, [memberId]);

  // Component functions
  const updatingSelectedContact = () => {
    if (!selectedContact) {
      let conversationsSorted = queryString.includes('userId')
        ? getConversationsBySelectedChatType(chatType)
        : getConversationsBySelectedChatType();
      const userId = queryString.includes('userId') ? params.get('userId') : location?.state?.chatId || id;
      const isCareTeamFlag = params.get('isDirectCall');
      const searchKey = searchQuery?.trim()?.toLowerCase();
      if (searchKey?.length) {
        conversationsSorted = conversationsSorted?.filter(
          item =>
            item.nickName?.toLowerCase().includes(searchKey) ||
            item.fullName?.toLowerCase().includes(searchKey),
        );
      }
      if (userId) {
        const queryChatType = queryString.includes('type') ? params.get('type') : 'all';
        setChatType(queryChatType);
        let userFound = conversationsSorted.find(item => item.connectionId === userId);
        if (isCareTeamFlag === 'true') {
          userFound = conversationsSorted.find(
            item => item.connectionId === userId && item.type === CONNECTIONS_TYPES.CARE_TEAM,
          );
        }
        if (userFound) {
          const queryParams = {
            searchQuery: userFound?.firstName || userFound?.fullName,
            pageNumber: 1,
            pageSize: 10,
            orderBy: '',
            sortBy: '',
          };
          dispatch(profileActionCreators.fetchPatients(queryParams));
          setSelectedContact(userFound);
          setMemberId(userFound.connectionId);
          setInstantAppointmentObj({
            ...InstantAppointmentObj,
            member: {
              id: userFound?.connectionId,
            },
          });
        } else if (conversationsSorted.length > 0) {
          const queryParams: any = {
            userId: meta.userId,
            type: chatType ?? 'members',
            searchByConnectionId: userId,
          };
          void MemberService.getPaginatedConnections(queryParams)
            .then(({ data }) => {
              const { results } = data;
              if (results.length > 0) {
                dispatch(profileActionCreators.storeSpecificConnection(results[0]));
                setSelectedContact(results[0]);
                setMemberId(userId);
                setInstantAppointmentObj({
                  ...InstantAppointmentObj,
                  member: {
                    id: userId,
                  },
                });
              } else {
                dispatch(
                  showSnackbar({
                    snackType: 'error',
                    snackMessage: 'User is not an active connection. Cannot initiate chat',
                  }),
                );
              }
            })
            .catch(e => {
              console.warn(e);
              dispatch(
                showSnackbar({
                  snackType: 'error',
                  snackMessage: 'Something went wrong. Please try again later.',
                }),
              );
            });
        }
      } else {
        setSelectedContact(null);
      }
    }
  };

  /**
   * @Name handleAddMessage
   * @param message
   * @description This method is used to handle add message functionality through redux call .
   */
  const handleAddMessage = (msg: Record<string, any>) => {
    const uniqueProviders = selectedUser?.filter(
      (obj, index) => selectedUser?.findIndex(item => item.providerId === obj.providerId) === index,
    );
    let mentionedProvidersIds = [];
    if (uniqueProviders?.length !== 0) {
      mentionedProvidersIds = uniqueProviders?.map(provider => provider.providerId);
    }
    const newMessage = !msg.hasFile
      ? {
          _id: uuid4(),
          parentMessageId: messageThread.messageId === 0 ? 0 : messageThread.messageId,
          message: {
            text: msg,
          },
          user: {
            userId: isAdmin ? meta?.userId : profile?.providerId,
            name: isAdmin ? meta?.nickName : profile?.fullName,
            avatar: isAdmin ? '' : profile?.profileImage,
          },
          mentionedProviders: uniqueProviders,
          mentionedProvidersIds,
          memberId:
            selectedContact.type === 'CARE_TEAM'
              ? selectedContact?.id || selectedContact?.connectionId
              : null,
          nickName: isAdmin ? meta?.nickName : profile.fullName ?? 'no name',
          createdAt: new Date().getTime(),
          type: 'user',
          system: false,
          data: messageThread,
        }
      : {
          ...msg,
          parentMessageId: messageThread.messageId === 0 ? 0 : messageThread.messageId,
          nickName: isAdmin ? meta?.nickName : profile.fullName ?? 'no name',
          data: messageThread,
        };
    if (uniqueProviders.length !== 0) {
      setShowTodoDialog(true);
    }
    dispatch(
      conversationActionCreators.sendMessage({
        payload: {
          message: newMessage,
        },
      }),
    );
    setMessageThread({
      messageId: 0,
      message: '',
      nickName: '',
      colorCode: '',
      userId: '',
      name: '',
      avatar: '',
      thumbnail: '',
      createdDate: '',
      fileMeta: {
        type: '',
        url: '',
      },
    });
    setSelectedUser([]);
  };

  const getChatTabType = type => {
    if (type === CONNECTIONS_TYPES.PATIENT) {
      return 'member';
    }
    if (type === CONNECTIONS_TYPES.PRACTITIONER) {
      return 'provider';
    }
    if (type === CONNECTIONS_TYPES.CARE_TEAM) {
      return 'care_team';
    }
    return 'group';
  };

  const deleteConnectionWithMember = () => {
    dispatch(
      conversationActionCreators.deleteMemberConnection(selectedContact?.id || selectedContact?.connectionId),
    );
    setIsCreateConnection(false);
    history.push('/admin/chats');
  };

  /**
   * @Name onSelectContact
   * @param contact
   * @description This method is used to save selected contact
   */
  const onSelectContact = (contact: SideUserDataT) => () => {
    const foundConnection = connectionsList.find(
      conn => conn.connectionId === contact.connectionId && conn.type === contact.type,
    );
    const concernedConnection = foundConnection ?? contact;
    setSelectedUser([]);
    setAllOldMessagesFetched(false);
    setMessageThread({
      messageId: 0,
      message: '',
      nickName: '',
      colorCode: '',
      userId: '',
      name: '',
      avatar: '',
      thumbnail: '',
      createdDate: '',
      fileMeta: {
        type: '',
        url: '',
      },
    });
    const queryParams = {
      searchQuery: concernedConnection?.firstName || concernedConnection?.fullName,
      pageNumber: 1,
      pageSize: 30,
      orderBy: '',
      sortBy: '',
    };
    dispatch(profileActionCreators.fetchPatients(queryParams));
    dispatch(conversationActionCreators.fetchAllTodo());
    setSelectedContact(concernedConnection);
    setMemberId(concernedConnection?.connectionId);
    setInstantAppointmentObj({
      ...InstantAppointmentObj,
      member: {
        id: concernedConnection?.connectionId,
      },
    });
  };

  const onCloseAddNote = () => {
    setIsOpenAddNote(false);
    setSelectedNote(null);
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            setOpenInstantAppointmentDrawer(false);
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const sortConnections = connections => {
    const unreadMessageConnections = connections
      ?.filter(conn => conn.unreadMessageCount > 0)
      ?.sort((contact1: any, contact2: any) => {
        let timestamp1 = contact1?.lastMessageTimestampUnix;
        let timestamp2 = contact2?.lastMessageTimestampUnix;
        if (!timestamp1) {
          timestamp1 = 0;
        }
        if (!timestamp2) {
          timestamp2 = 0;
        }
        return timestamp2 - timestamp1;
      });

    const restConnections = connections
      ?.filter(conn => !(conn.unreadMessageCount > 0))
      ?.sort((contact1: any, contact2: any) => {
        let timestamp1 = contact1?.lastMessageTimestampUnix;
        let timestamp2 = contact2?.lastMessageTimestampUnix;
        if (!timestamp1) {
          timestamp1 = 0;
        }
        if (!timestamp2) {
          timestamp2 = 0;
        }
        return timestamp2 - timestamp1;
      });
    return unreadMessageConnections?.length > 0 && restConnections?.length > 0
      ? [...unreadMessageConnections, ...restConnections]
      : unreadMessageConnections?.length > 0
      ? unreadMessageConnections
      : restConnections?.length > 0
      ? restConnections
      : [];
  };

  function getChatConnectionsByChatType(
    chatsConnections: any,
    userGroupConnections: IUserGroup[],
    types: string[] = [
      CONNECTIONS_TYPES.PATIENT,
      CONNECTIONS_TYPES.PRACTITIONER,
      CONNECTIONS_TYPES.CARE_TEAM,
      CONNECTIONS_TYPES.CARE_NAVIGATOR,
      CONNECTIONS_TYPES.CHAT_GROUP,
      'ALL',
    ],
  ) {
    let patients = [];
    let practitioners = [];
    let careTeamMembers = [];
    let careNavigatorConnections = [];
    let connectionsAll = [];
    const groupsConnections = [];

    if (types.includes(CONNECTIONS_TYPES.PATIENT)) {
      patients = chatsConnections.members.connections.map(activeConnection => {
        return {
          ...activeConnection,
          id: activeConnection?.connectionId,
          type: activeConnection?.type,
          role: activeConnection?.type,
          messages: activeConnection?.messages,
          lastMessage: activeConnection?.lastMessage,
          lastUpdated: activeConnection?.lastMessageTimestampUnix,
          nickName: activeConnection?.nickName,
          fullName: activeConnection?.name,
          thumbnail: activeConnection?.profilePicture,
          channelUrl: activeConnection?.channelUrl,
          colorCode: activeConnection?.colorCode,
          uuid: activeConnection.uuid,
        } as SideUserDataT;
      });
    }
    if (types.includes(CONNECTIONS_TYPES.PRACTITIONER)) {
      practitioners = chatsConnections.providers.connections.map(activeConnection => {
        return {
          ...activeConnection,
          id: activeConnection?.connectionId,
          type: activeConnection?.type,
          role: activeConnection?.type,
          messages: activeConnection?.messages,
          lastMessage: activeConnection?.lastMessage,
          lastUpdated: activeConnection?.lastMessageTimestampUnix,
          nickName: activeConnection?.nickName,
          fullName: activeConnection?.name,
          thumbnail: activeConnection?.profilePicture,
          channelUrl: activeConnection?.channelUrl,
          colorCode: activeConnection?.colorCode,
        } as SideUserDataT;
      });
    }
    if (types.includes(CONNECTIONS_TYPES.CARE_TEAM)) {
      careTeamMembers = chatsConnections.careTeam?.connections.map(activeConnection => {
        return {
          ...activeConnection,
          id: activeConnection?.connectionId,
          type: activeConnection?.type,
          role: activeConnection?.type,
          messages: activeConnection?.messages,
          lastMessage: activeConnection?.lastMessage,
          lastUpdated: activeConnection?.lastMessageTimestampUnix,
          nickName: activeConnection?.nickName,
          fullName: activeConnection?.name,
          thumbnail: activeConnection?.profilePicture,
          channelUrl: activeConnection?.channelUrl,
          colorCode: activeConnection?.colorCode,
        } as SideUserDataT;
      });
    }
    if (types.includes(CONNECTIONS_TYPES.CARE_NAVIGATOR)) {
      careNavigatorConnections = chatsConnections.careNavigator?.connections?.map(activeConnection => {
        return {
          ...activeConnection,
          id: activeConnection?.connectionId,
          type: activeConnection?.type,
          role: activeConnection?.type,
          messages: activeConnection?.messages,
          lastMessage: activeConnection?.lastMessage,
          lastUpdated: activeConnection?.lastMessageTimestampUnix,
          nickName: activeConnection?.nickName,
          fullName: activeConnection?.name,
          thumbnail: activeConnection?.profilePicture,
          channelUrl: activeConnection?.channelUrl,
          colorCode: activeConnection?.colorCode,
        } as SideUserDataT;
      });
    }
    if (types.includes(CONNECTIONS_TYPES.CHAT_GROUP)) {
      userGroupConnections.forEach(userGroup => {
        groupsConnections.push({
          ...userGroup,
          id: userGroup?.channelUrl,
          connectionId: userGroup?.channelUrl,
          type: CONNECTIONS_TYPES.CHAT_GROUP,
          messages: [],
          lastUpdated: '-',
          nickName: userGroup?.name,
          fullName: userGroup?.name,
          thumbnail: userGroup?.groupImage,
          channelUrl: userGroup?.channelUrl,
          lastMessage: '',
          lastMessageTimestampUnix: '',
        });
      });
    }
    if (types.includes('ALL') && chatType === 'all') {
      connectionsAll = chatsConnections.all?.connections?.map(activeConnection => {
        const isGroup = activeConnection.type === CONNECTIONS_TYPES.CHAT_GROUP;
        return {
          ...activeConnection,
          id: isGroup ? activeConnection?.channelUrl : activeConnection?.connectionId,
          type: activeConnection?.type,
          role: activeConnection?.type,
          messages: isGroup ? [] : activeConnection?.messages,
          lastMessage: isGroup ? '' : activeConnection?.lastMessage,
          lastUpdated: isGroup ? '-' : activeConnection?.lastMessageTimestampUnix,
          nickName: isGroup ? activeConnection.name : activeConnection?.nickName,
          fullName: isGroup ? activeConnection.name : activeConnection?.name,
          thumbnail: isGroup ? activeConnection.groupImage : activeConnection?.profilePicture,
          channelUrl: activeConnection?.channelUrl,
          colorCode: activeConnection?.colorCode,
          connectionId: activeConnection?.channelUrl,
          lastMessageTimestampUnix: '',
        };
      });
    }

    const uniqueFilterConnections = new Map<string, SideUserDataT>();

    const addUniqueConnections = (connections: SideUserDataT[] = []) => {
      connections.forEach(connection => {
        if (!uniqueFilterConnections.has(connection.channelUrl)) {
          uniqueFilterConnections.set(connection.channelUrl, connection);
        }
      });
    };

    types.forEach(type => {
      if (type === CONNECTIONS_TYPES.PATIENT) {
        addUniqueConnections(patients);
      }
      if (type === CONNECTIONS_TYPES.PRACTITIONER) {
        addUniqueConnections(practitioners);
      }
      if (type === CONNECTIONS_TYPES.CARE_TEAM) {
        addUniqueConnections(careTeamMembers);
      }
      if (type === CONNECTIONS_TYPES.CARE_NAVIGATOR) {
        addUniqueConnections(careNavigatorConnections);
      }
      if (type === CONNECTIONS_TYPES.CHAT_GROUP) {
        addUniqueConnections(groupsConnections);
      }
      if (type === 'ALL' && chatType === 'all') {
        addUniqueConnections(connectionsAll);
      }
    });

    const allConnections = Array.from(uniqueFilterConnections.values());

    if (types.length > 0) {
      return allConnections;
    }
    return {
      members: patients,
      providers: practitioners,
      groups: groupsConnections,
      careTeam: careTeamMembers?.length > 0 ? careTeamMembers : [],
      careNavigators: careNavigatorConnections,
      all: connectionsAll,
    } as ConnectionByTypeT;
  }

  /**
   * @Name getConversationsBySelectedChatType
   * @description This method is used to get conversation list by selected chat type
   */
  const getConversationsBySelectedChatType = (type = '') => {
    const selectedTab = type || chatType;
    switch (selectedTab) {
      case 'members':
        return chatConnections?.filter(item => item.type === CONNECTIONS_TYPES.PATIENT);
      case 'providers':
        return chatConnections?.filter(item => item.type === CONNECTIONS_TYPES.PRACTITIONER);
      case 'care_team':
        return chatConnections?.filter(item => item.type === CONNECTIONS_TYPES.CARE_TEAM);
      case 'groups':
        return chatConnections?.filter(item => item.type === CONNECTIONS_TYPES.CHAT_GROUP);
      case 'care_navigator':
        return chatConnections?.filter(item => item.type === CONNECTIONS_TYPES.CARE_NAVIGATOR);
      default:
        return chatConnections;
    }
  };

  const getProfilePictureFromChannel = userId => {
    let url = null;
    if (channelMembers && channelMembers.length > 0) {
      const channelMember = channelMembers.find(member => member.userId === userId);
      if (channelMember) {
        url = channelMember.profileUrl;
      }
    }
    if (url?.includes('/sample/')) {
      return null;
    }
    return url;
  };

  const onClickProfile = () => {
    if (
      !isAdmin &&
      (selectedContact?.type === 'PATIENT' || selectedContact?.type === 'CARE_TEAM') &&
      selectedContact
    ) {
      history.push(`/provider/members/${selectedContact?.connectionId}?name=dashboard`);
    }
    if (
      isAdmin &&
      (selectedContact?.type === 'PATIENT' || selectedContact?.type === 'CARE_TEAM') &&
      selectedContact
    ) {
      history.push(`/admin/members/${selectedContact?.connectionId}?name=dashboard`);
    }
  };

  /**
   * @Name getConnectionDetailsByUserId
   * @params userId
   * @description This method is used to get profile picture by userId
   */
  const getConnectionDetailsByUserId = (userId: string) => {
    const connections =
      selectedContact?.role === 'PATIENT'
        ? chats.members.connections
        : selectedContact?.role === 'PRACTITIONER'
        ? chats.providers.connections
        : selectedContact?.role === 'CARE_TEAM'
        ? chats.careTeam.connections
        : selectedContact?.role === CONNECTIONS_TYPES.CARE_NAVIGATOR
        ? chats.careNavigator?.connections
        : null;
    let connection = null;
    if (connections) {
      connection = connections?.find(
        (activeConnection: IConnection) => activeConnection?.connectionId === userId,
      );
    }
    if (!connection) {
      connection = userGroups?.find((userGroup: any) => userGroup?.channelUrl === userId);
    }
    return connection;
  };

  const closeScheduleAppointmentDialog = () => {
    showRequestAppointmentDialog(false);
    setRequestAppointmentParams(null);
  };

  const getTypeCountObject = (key: string, counts: any) => {
    switch (key) {
      case 'cautionNotesCount':
        return {
          name: 'Coutionary',
          value: counts[key],
          color: colors.warning500,
        };
      case 'contactNotesCount':
        return {
          name: 'Contact',
          value: counts[key],
          color: colors.primary500,
        };
      case 'generalNotesCount':
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
      case 'labRequestNotesCount':
        return {
          name: 'Lab Request',
          value: counts[key],
          color: colors.green500,
        };
      case 'pharmacyAssistanceNotesCount':
        return {
          name: 'Pharmacy Assistance',
          value: counts[key],
          color: colors.cyan500,
        };
      case 'priorAuthorizationNotesCount':
        return {
          name: 'Prior Authorization',
          value: counts[key],
          color: colors.primary500,
        };
      case 'prohibitiveNotesCount':
        return {
          name: 'Prohibitive',
          value: counts[key],
          color: colors.destructive500,
        };
      case 'referralOutNotesCount':
        return {
          name: 'Referral out',
          value: counts[key],
          color: colors.orange500,
        };
      case 'releaseOfInformationNotesCount':
        return {
          name: 'Release of info',
          value: counts[key],
          color: colors.indigo500,
        };
      default:
        return {
          name: 'General',
          value: counts[key],
          color: colors.primary500,
        };
    }
  };

  const getFormatedCountsByNoteTypes = (counts: any) => {
    if (counts) {
      return Object.keys(counts)
        ?.filter(function (key) {
          if (key !== 'totalNotesCount') return key;
          return null;
        })
        ?.map(function (key) {
          return getTypeCountObject(key, counts);
        });
    }
    return null;
  };

  const getStatusCountObject = (key: string, counts: any, statusCounts: any) => {
    switch (key) {
      case 'receivedByCareManagementCount':
      case 'reportedCount':
      case 'sentToMemberCount':
      case 'requestedCount':
      case 'sentToLabCount':
      case 'submittedCount':
      case 'pendingCount':
      case 'SentToRequestedContactCount':
        statusCounts[0].value += counts[key];
        break;
      case 'reviewedByManagementCount':
      case 'resolvedCount':
      case 'completedCount':
      case 'unsuccessfulCount':
      case 'removedCount':
      case 'rejectedCount':
      case 'noResponseCount':
        statusCounts[1].value += counts[key];
        break;
      default:
        break;
    }
  };

  const getFormatedCountsByNotesStatus = (counts: any) => {
    if (counts) {
      const statusCounts = [
        {
          name: 'Needs action',
          color: colors.neutral900,
          value: 0,
        },
        {
          name: 'No action needed',
          color: colors.neutral200,
          value: 0,
        },
      ];
      Object.keys(counts)
        ?.filter(key => key !== 'totalNotesCount')
        ?.map(key => getStatusCountObject(key, counts, statusCounts));
      return statusCounts;
    }
    return null;
  };

  const onReloadChat = () => {
    window.location.reload();
  };

  const loadMore = () => {
    if (chatType === 'members' && chats.members.loadingMore) {
      return;
    }
    if (chatType === 'providers' && chats.providers.loadingMore) {
      return;
    }
    if (chatType === 'care_team' && chats.careTeam.loadingMore) {
      return;
    }
    if (chatType === 'care_navigator' && chats.careNavigator.loadingMore) {
      return;
    }
    if (chatType === 'all' && chats.all.loadingMore) {
      return;
    }
    dispatch(
      profileActionCreators.loadMoreConnections({
        userId: meta?.userId,
        type: meta.authority === 'BILLING_SUPPORT' || meta.authority === 'ADMIN' ? 'members' : chatType,
        search: searchQuery,
      }),
    );
  };

  const onClickAddNewNote = () => {
    setIsOpenAddNote(true);
  };

  const onUpdateNote = (note: INote) => {
    setSelectedNote(note);
    onClickAddNewNote();
  };

  const handleRefetch = () => {
    void fetchNotes();

    if (refetchNotes) {
      refetchNotes();
    }
  };

  const handleDeselectNote = () => {
    setSelectedNote(null);
  };

  function getLastMessageFromChannel(channel, connection) {
    if (connection.lastMessageTimestampUnix > channel.lastMessage?.createdAt) {
      return {
        message: connection.lastMessage,
        timestamp: connection.lastMessageTimestampUnix ?? connection.lastModified,
      };
    }
    if (channel.lastMessage) {
      if (channel.lastMessage.messageType === 'file') {
        return { message: 'Attachment', timestamp: channel?.lastMessage?.createdAt };
      }
      return {
        message: channel.lastMessage.message,
        timestamp:
          channel.lastMessage?.createdAt ?? connection.lastMessageTimestampUnix ?? connection.lastModified,
      };
    }
    return { message: '', timestamp: '' };
  }

  const getDesignation = (designation: string, isHeaderIcons = true) => {
    const designationMapping = {
      [CONNECTIONS_TYPES.PATIENT]: { icon: 'heart-outlined', label: 'Patient', color: '#2DA834' },
      [CONNECTIONS_TYPES.PRACTITIONER]: { icon: 'diagnoses', label: 'Provider', color: '#F63D68' },
      [CONNECTIONS_TYPES.CARE_TEAM]: { icon: 'multiple-users', label: 'Care Team', color: colors.cyan500 },
      [CONNECTIONS_TYPES.CHAT_GROUP]: { icon: 'members-outlined', label: 'Group', color: '#EF6820' },
      [CONNECTIONS_TYPES.CARE_NAVIGATOR]: {
        icon: 'multiple-users',
        label: 'Care Navigator',
        color: '#6172F3',
      },
      currentUser: { icon: 'heart-outlined', label: 'You', color: '#F63D68' },
      default: { icon: 'multiple-users', label: 'Admin', color: '#0374DD' },
    };
    const { icon, label, color } = designationMapping[designation] || designationMapping.default;
    return (
      <Box display="flex" gap="4px" alignItems="center" color={color}>
        <Icons
          color={color}
          className={isHeaderIcons ? classes.headerIcons : classes.headerIcons}
          glyph={icon as any}
        />
        <Box className={classes.headerDesignationText}>{label}</Box>
      </Box>
    );
  };

  const getSelectedContactFullName = () => {
    if (
      selectedContact.type === CONNECTIONS_TYPES.PATIENT ||
      selectedContact.type === CONNECTIONS_TYPES.CARE_TEAM
    ) {
      if (selectedContact?.firstName && selectedContact?.lastName)
        return `${selectedContact?.firstName} ${selectedContact?.lastName}`;
    }
    return selectedContact?.fullName ?? selectedContact?.name;
  };

  const handleLoadMoreMessages = () => {
    if (selectedContact?.channelUrl && !showLoadingMoreMessages) {
      setShowLoadingMoreMessages(true);

      const fetchParams = {
        channelUrl: selectedContact?.channelUrl,
        limit: 200,
        message_ts: messages?.[0]?.createdAt ?? Date.now(),
      };
      // Check if we need to fetch from the old app
      if (isLoadedAllMessages && !allOldMessagesFetched) {
        // Fetch old messages from the API
        fetchMessagesFromOldApp(fetchParams)
          .then(oldMessages => {
            const { messages: oldChatMessages } = oldMessages.data;

            if (oldChatMessages.length < 200) setAllOldMessagesFetched(true); // No more messages

            if (oldChatMessages.length > 0) {
              const chatMessages = oldChatMessages.map(mapMessageToGiftedChat);

              dispatch(conversationActionCreators.loadOldMessages({ chatMessages }));
            }
            setShowLoadingMoreMessages(false);
          })
          .catch(err => {
            setShowLoadingMoreMessages(false);
            dispatch(
              showSnackbar({
                snackType: 'error',
                snackMessage: err.data?.error ?? 'Unable to fetch previous messages at the moment',
              }),
            );
            console.log({ err });
          });
      } else {
        // Fetch new messages using the Sendbird SDK
        dispatch(conversationActionCreators.loadMoreChatMessages(fetchParams));
      }
    }
  };

  const handleUpdateSeen = () => {
    setShowUpdatesModal(false);
    localStorage.setItem('hasSeenUpdate', 'true');
  };

  const handleSchedulingAppointment = ai => {
    if (ai) setAutomatedSchedulingData(ai);
    setOpenInstantAppointmentDrawer(true);
  };

  // Component Logic
  if (!isMounted.current) {
    const hasConnections =
      chats?.careNavigator?.connections?.length > 0 ||
      chats.members?.connections?.length > 0 ||
      chats.providers?.connections?.length > 0 ||
      chats.careTeam?.connections?.length > 0;
    if (!hasConnections) {
      setConnectionsList([]);
      dispatch(profileActionCreators.clearChats());
      setIsLoadingChatConnections(true);
      dispatch(conversationActionCreators.fetchChats({ userId: profile?.providerId }));
      isMounted.current = true;
    }
  }

  const isOtherChatLink =
    location.pathname.includes('/provider/chats/chats-with-members/') ||
    location.pathname.includes('/provider/chats/chats-with-providers/') ||
    location.pathname.includes('/provider/chats/chats-with-groups/');

  const isDirectCall = selectedContact && selectedContact.type === 'CARE_TEAM' && channelMembers?.length > 0;

  const listLoading =
    (isAdmin && chats.members.isLoading && chatType === 'members') ||
    (chats.providers.isLoading && chatType === 'providers') ||
    (chats.careTeam?.isLoading && chatType === 'care_team') ||
    (chats.careNavigator?.isLoading && chatType === 'care_navigator') ||
    (groupsLoading && chatType === 'groups') ||
    isLoadingChatConnections ||
    (chatType === 'all' &&
      ((isAdmin && chats.members.isLoading) ||
        chats.providers.isLoading ||
        chats.careTeam?.isLoading ||
        chats.careNavigator?.isLoading ||
        groupsLoading));

  // Component render function
  return (
    <>
      <BaseLayout
        customRightSideBar={
          <div className={classes.rightSideBarRoot}>
            <Box className={classes.headerWrap}>
              <div className={classes.rightSideHeader}>
                {listLoading ? (
                  <Box padding="32px 16px">
                    <Loader type={LoaderEnums.SEARCH} numItems={1} />
                  </Box>
                ) : showSearchBar ? (
                  <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    px={2}
                  >
                    <Input
                      placeholder="Search"
                      className={classes.searchChat}
                      size={inputSize.S}
                      value={searchQuery}
                      onChange={onChangeSearchKey}
                      variant={inputType.SEARCH}
                      fullWidth
                    />
                    <Box
                      sx={{
                        '& button': {
                          minWidth: 24,
                          '& svg path': {
                            fill: colors.neutral400,
                          },
                        },
                      }}
                    >
                      <IconButton
                        icon="close-circle-outlined"
                        size={btnSize.XSMALL}
                        onClick={() => {
                          setShowSearchBar(false);
                          setSearchQuery('');
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className={classes.chatSelectContainer}>
                    <SearchMenu
                      onOpen={() => setOpen(true)}
                      onClose={() => setOpen(false)}
                      options={
                        isAdmin
                          ? meta.authority === 'CARE_NAVIGATOR'
                            ? tabSelectOptionsCareNavigator(chatCounts)
                            : tabSelectOptionsAdmin(chatCounts)
                          : tabSelectOptions(chatCounts)
                      }
                      onChange={item => {
                        setChatType(item.value);
                      }}
                      isAdmin={isAdmin}
                    />
                    <Box
                      sx={{
                        '& button svg': {
                          width: 20,
                          '& path': {
                            fill: colors.neutral400,
                          },
                        },
                      }}
                    >
                      <IconButton
                        icon="search-outlined"
                        size={btnSize.XSMALL}
                        onClick={() => setShowSearchBar(true)}
                      />
                    </Box>
                  </Box>
                )}
              </div>
            </Box>
            <div className={classes.userChatList}>
              {listLoading || searchContactLoading ? (
                <Box padding="16px">
                  <Loader type={LoaderEnums.CHAT_USER_CARD} numItems={12} />
                </Box>
              ) : (
                <>
                  {connectionsList?.length > 0 ? (
                    connectionsList.map((item: SideUserDataT) => (
                      <SideUserCard
                        key={
                          item.type === CONNECTIONS_TYPES.CARE_TEAM
                            ? `${item.connectionId}-careteam`
                            : item.connectionId
                        }
                        item={item}
                        isActive={
                          item.connectionId === selectedContact?.connectionId &&
                          item.type === selectedContact?.type
                        }
                        selectContact={onSelectContact(item)}
                        onlineUsers={onlineUsers}
                        getDesignation={getDesignation}
                      />
                    ))
                  ) : (
                    <div className="noRecord">
                      {!chats.searchResults.isLoading && (
                        <Text weight={fontWeight.SEMI_BOLD} level={textLevel.S}>
                          No record found
                        </Text>
                      )}
                    </div>
                  )}
                  {searchQuery?.trim()?.length === 0 && (
                    <Box className={classes.loadMoreContainer}>
                      {chatType === 'members' && chats.members.hasMore && (
                        <Button
                          disabled={chats.members.loadingMore}
                          onClick={loadMore}
                          variant={btnType.TEXT}
                        >
                          {chats.members.loadingMore ? 'Loading...' : 'Load More'}
                        </Button>
                      )}
                      {chatType === 'providers' && chats.providers.hasMore && (
                        <Button
                          disabled={chats.providers.loadingMore}
                          onClick={loadMore}
                          variant={btnType.TEXT}
                        >
                          {chats.providers.loadingMore ? 'Loading...' : 'Load More'}
                        </Button>
                      )}
                      {chatType === 'care_team' && chats.careTeam?.hasMore && (
                        <Button
                          disabled={chats.careTeam?.loadingMore}
                          onClick={loadMore}
                          variant={btnType.TEXT}
                        >
                          {chats.careTeam?.loadingMore ? 'Loading...' : 'Load More'}
                        </Button>
                      )}
                      {chatType === 'care_navigator' && chats.careNavigator?.hasMore && (
                        <Button
                          disabled={chats.careNavigator?.loadingMore}
                          onClick={loadMore}
                          variant={btnType.TEXT}
                        >
                          {chats.careNavigator?.loadingMore ? 'Loading...' : 'Load More'}
                        </Button>
                      )}
                      {chatType === 'all' && chats.all?.hasMore && (
                        <Button disabled={chats.all?.loadingMore} onClick={loadMore} variant={btnType.TEXT}>
                          {chats.all?.loadingMore ? 'Loading...' : 'Load More'}
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              )}
            </div>
          </div>
        }
        rightSideBarWidth="400px"
        isChatNav
      >
        {requestAppointmentParams && (
          <ScheduleAppointment
            isOpen={requestAppointmentDialog}
            onClose={closeScheduleAppointmentDialog}
            startTime={requestAppointmentParams?.startTime}
            serviceId={requestAppointmentParams?.serviceId}
            providerId={requestAppointmentParams?.providerId}
            memberId={requestAppointmentParams?.memberId}
            memberConnection={selectedContact}
          />
        )}
        <div className={classes.root} ref={elementRef}>
          <ChatHeader
            getConnectionDetailsByUserId={getConnectionDetailsByUserId}
            selectedContact={selectedContact}
            isLoading={isLoading}
            chatType={chatType}
            onClickProfile={onClickProfile}
            setOpenCareTeamDrawer={setOpenCareTeamDrawer}
            careTeamDetail={careTeamDetail}
            getSelectedContactFullName={getSelectedContactFullName}
            isAdmin={isAdmin}
            getProfilePictureFromChannel={getProfilePictureFromChannel}
            onlineUsers={onlineUsers}
            demographicDetails={demographicDetails}
            hideDOB={hideDOB}
            hideVisitFrequency={hideVisitFrequency}
            hideNextAppointment={hideNextAppointment}
            nextAppointment={hideNextAppointment}
            params={params}
            getDesignation={getDesignation}
            setShowCareTeamJoinCall={setShowCareTeamJoinCall}
            channelUrl={channelUrl}
            setOpenInstantAppointmentDrawer={setOpenInstantAppointmentDrawer}
            setIsOpenAddNote={setIsOpenAddNote}
            providerAvailability={providerAvailability}
            setOpenVisitFrequencyDrawer={setOpenVisitFrequencyDrawer}
            setIsCreateConnection={setIsCreateConnection}
          />
          <Box
            paddingBottom={selectedContact?.type === 'CHAT_GROUP' ? '77px !important' : ''}
            ref={containerRef}
            className={clsx(classes.messageBody, {
              [classes.fullMessageBodyHeight]: !selectedContact || isLoading,
            })}
            marginTop={selectedContact === null ? '-25px' : '0px'}
            onScroll={onScrollElement}
          >
            {selectedContact && !isLoading && showLoadMoreBtn && (
              <Box className={classes.loadMoreBtnWrapper} onClick={handleLoadMoreMessages}>
                <IconButton
                  icon="arrow-up"
                  className={showLoadingMoreMessages ? classes.loadMoreBtnLoading : classes.loadMoreBtn}
                  variant={btnType.TEXT}
                  disabled={showLoadingMoreMessages}
                />
                <Text
                  className={
                    showLoadingMoreMessages
                      ? classes.loadMoreBtnTextWrapperDisabled
                      : classes.loadMoreBtnTextWrapper
                  }
                >
                  Load older messages
                </Text>
              </Box>
            )}
            {isLoading && !!selectedContact ? (
              <Loader type={LoaderEnums.CHAT_MESSAGE} numItems={8} />
            ) : selectedContact && messages?.length > 0 ? (
              <>
                {messages?.map(msg => (
                  <NewUserChatCard
                    key={msg._id}
                    {...msg}
                    messageId={msg._id}
                    scrollMessageId={location?.state?.messageId}
                    senderId={msg.user.userId}
                    currentUserId={profile.providerId ?? meta.userId}
                    currentUserImage={profile?.profileImage}
                    mentionedUsers={msg.mentionedUsers}
                    createdDate={msg.createdAt}
                    setMessageThread={setMessageThread}
                    showTodoDialog={showTodoDialog}
                    selectedContact={selectedContact}
                    recentMessageSend={recentMessageSend}
                    inputRef2={inputRef2}
                    prevMsg={msg.prevMsg}
                    getConnectionDetailsByUserId={getConnectionDetailsByUserId}
                    thumbnail={!msg?.user?.avatar?.includes('/sample/') ? msg.user.avatar : ''}
                    colorCode={
                      profile.providerId !== msg.user?.userId
                        ? getConnectionDetailsByUserId(msg.user?.userId)?.colorCode
                        : null
                    }
                    getDesignation={getDesignation}
                    careTeamDetail={careTeamDetail}
                  />
                ))}
                {showLatestBtn && messageThread.messageId === 0 && (
                  <Box
                    className={clsx({
                      [classes.latest2]: isOtherChatLink,
                      [classes.latest]: !isOtherChatLink,
                    })}
                  >
                    <IconButton
                      icon="arrow-left"
                      className={classes.latestBtn}
                      variant={btnType.PRIMARY}
                      onClick={() => {
                        containerRef?.current?.scroll({
                          top: containerRef?.current?.scrollHeight,
                          behavior: 'smooth',
                          left: 0,
                        });
                        setShowLatestBtn(false);
                      }}
                    >
                      Latest messages
                    </IconButton>
                  </Box>
                )}
              </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <PriorityEmpty
                  title={
                    selectedContact ? 'No messages have been sent yet.' : 'Select a contact to start chat'
                  }
                  description={
                    selectedContact
                      ? 'Start typing below to send the first message.'
                      : 'You can also search in the top bar to find a contact.'
                  }
                  glyph="alfie-with-heart-bubble"
                  glyphOrientation="landscape"
                  className={classes.emptyState}
                />
                {errorMsg && (
                  <Button variant={btnType.PRIMARY} className={classes.reloadBtn} onClick={onReloadChat}>
                    Reload chat
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {messageThread.messageId !== 0 && (
            <MessageThread
              messageThread={messageThread}
              onClose={() => {
                setMessageThread({
                  messageId: 0,
                  message: '',
                  nickName: '',
                  colorCode: '',
                  userId: '',
                  name: '',
                  avatar: '',
                  thumbnail: '',
                  createdDate: '',
                  fileMeta: {
                    type: '',
                    url: '',
                  },
                });
              }}
            />
          )}
          {selectedContact && (
            <div
              className={
                location.pathname.includes('/provider/chats/chats-with-members/') ||
                location.pathname.includes('/provider/chats/chats-with-providers/') ||
                location.pathname.includes('/provider/chats/chats-with-groups/')
                  ? classes.footer
                  : classes.footer2
              }
            >
              {isLoading ? (
                <Loader type={LoaderEnums.CHAT_INPUT} />
              ) : (
                <MessageInput
                  lastMessageId={messages[messages.length - 1]?._id}
                  onSubmit={handleAddMessage}
                  username={selectedContact?.fullName ?? selectedContact?.name}
                  setSelectedUser={setSelectedUser}
                  selectedContact={selectedContact}
                  selectedUser={selectedUser}
                  channelMembers={channelMembers}
                  currentUserId={profile.providerId}
                  onOpportunityBoxChanged={setOpportunityVisible}
                  inputRef2={inputRef2}
                  onOpenAppointment={handleSchedulingAppointment}
                  onOpenCaseNotes={() => setIsOpenAddNote(true)}
                />
              )}
            </div>
          )}
        </div>
      </BaseLayout>
      {openInstantAppointmentDrawer && (
        <AddSchedule
          isOpen={openInstantAppointmentDrawer}
          onClose={() => setOpenInstantAppointmentDrawer(false)}
          onSubmit={onSubmitNewSchedule}
          memberId={selectedContact?.connectionId}
          memberDetails2={selectedContact}
          automatedSchedulingData={automatedSchedulingData}
        />
      )}
      {openVisitFrequencyDrawer && (
        <UpdateVisitFrequency
          openDrawer={openVisitFrequencyDrawer}
          onClose={() => setOpenVisitFrequencyDrawer(false)}
          data={frequencyDataObj}
          currentUserId={memberId}
        />
      )}
      {isCreateConnection && (
        <CreateConnectionModal
          icon="x-circle-outlined"
          open={isCreateConnection}
          title="Delete Connection"
          content="Do you want to delete connection with this member?"
          lbtnLabel="Cancel"
          rbtnLabel="Confirm"
          onClose={() => setIsCreateConnection(false)}
          onCancel={() => setIsCreateConnection(false)}
          onSubmit={deleteConnectionWithMember}
          isLoading={false}
          isDelete={isDelete}
        />
      )}
      {isOpenAddNote && (
        <>
          <AddNoteDrawer
            isOpen={isOpenAddNote}
            onClose={onCloseAddNote}
            fetchNotes={handleRefetch}
            note={selectedNote}
            memberId={memberId}
          />
          <NoteDrawer
            selectedNote={isOpenAddNote ? undefined : selectedNote}
            onClose={handleDeselectNote}
            onUpdateNote={onUpdateNote}
          />
        </>
      )}
      {openCareTeamDrawer && (
        <CareTeamMembersDrawer
          title={`${
            selectedContact.type === CONNECTIONS_TYPES.CARE_TEAM
              ? 'Care team'
              : selectedContact?.fullName ?? selectedContact?.name
          }`}
          open={openCareTeamDrawer}
          channelUrl={selectedContact.channelUrl}
          careTeamMembers={
            selectedContact.type === CONNECTIONS_TYPES.CARE_TEAM ||
            selectedContact.type === CONNECTIONS_TYPES.CHAT_GROUP
              ? careTeamDetail
              : [selectedContact]
          }
          isProvider={!isAdmin}
          type={selectedContact.type}
          onSelectCareTeamMemberChat={selectedUserId => {
            const foundConnection = connectionsList.find(item => item.connectionId === selectedUserId);
            if (foundConnection) {
              setSelectedContact(foundConnection);
              onSelectContact(foundConnection);
              return;
            }
            if (queryString.includes('userId')) {
              const userId = params.get('userId');
              if (userId) {
                MemberService.getPaginatedConnections({ searchByConnectionId: selectedUserId })
                  .then(res => {
                    const fetchedconnection =
                      res.data?.results?.length > 0
                        ? res.data?.results?.find(conn => conn.type !== CONNECTIONS_TYPES.CARE_TEAM)
                        : null;
                    if (fetchedconnection) {
                      const newConnection = {
                        ...fetchedconnection,
                        colorCode: AVATAR_COLOR_ARRAY[AVATAR_COLOR_ARRAY.length - 1],
                      };
                      setChatConnections([newConnection, ...chatConnections]);
                      setSelectedContact(newConnection);
                      onSelectContact(newConnection);
                    }
                  })
                  .catch(err => console.log({ err }));
              }
            }
          }}
          onClose={() => setOpenCareTeamDrawer(false)}
        />
      )}
      <UpdatesModal
        open={showUpdatesModal}
        icon="chat-square"
        title="We've updated your chats!"
        onConfirm={handleUpdateSeen}
        confirmText="Got it"
        onClose={handleUpdateSeen}
      >
        <Box>
          We&apos;ve moved all 1:1 provider chats into the Care Team chat view. This update allows for faster
          response times and more comprehensive support.
        </Box>
        <Box marginTop={3}>
          {`Have any questions? Just ask - we're here to help, Thanks for being part of the Confidant
            community!`}
        </Box>
      </UpdatesModal>
    </>
  );
});

Chats.displayName = 'Chats';

export { Chats };
