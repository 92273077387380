import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, FieldArray } from 'formik';

import { Text, fontWeight, TextError } from '@confidant-health/lib/ui/atoms/typography';
import { Drawer, drawerType } from '@confidant-health/lib/ui/organisms/drawer';
import { Select, selectType } from '@confidant-health/lib/ui/atoms/select';

import dayjs from 'dayjs';
import { scheduleActionCreators } from 'redux/modules/schedule';
import { selectProviderSchedule, selectProviderScheduleSaving } from 'redux/modules/schedule/selectors';
import { WorkingDays } from 'constants/CommonConstants';
import { TimeHeading } from './time-heading/TimeHeading';
import { TimeSlot } from './time-slot/TimeSlot';

import { AvailabilitySchema } from './GeneralAvailability.schema';
import { US_TIMEZONES } from '../../Profile.constants';
import { useStyles } from './GeneralAvailability.styles';

type GeneralAvailabilityProps = {
  providerId: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  isCalendar?: boolean;
  currentWeekDay?: string;
  callback?: () => void;
};

const GeneralAvailability: React.FC<GeneralAvailabilityProps> = ({
  providerId,
  title = 'Edit general availability',
  isOpen,
  isCalendar,
  onClose,
  currentWeekDay,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSaving = useSelector(selectProviderScheduleSaving);
  const providerSchedule = useSelector(selectProviderSchedule);
  const filteredSchedule = providerSchedule?.availability.filter(item => item.active === true);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    providerSchedule?.timezone || US_TIMEZONES[0].value,
  );

  useEffect(() => {
    dispatch(scheduleActionCreators.fetchProviderSchedule({ userId: providerId }));
  }, [providerId]);

  const workingDays = useMemo(
    () =>
      filteredSchedule?.reduce((prev, item) => {
        const convertedSlots = item.slots.map(v => {
          return {
            start: dayjs(v.start).tz(selectedTimeZone).format('HH:mm'),
            end: dayjs(v.end).tz(selectedTimeZone).format('HH:mm'),
          };
        });
        return {
          ...prev,
          [item.day.toLowerCase()]: prev[item.day.toLowerCase()]
            ? [...prev[item.day.toLowerCase()], ...convertedSlots]
            : convertedSlots,
        };
      }, {}),
    [providerSchedule],
  );

  const weekdays = useMemo(() => {
    return currentWeekDay ? WorkingDays.filter(x => x === currentWeekDay.toLowerCase()) : WorkingDays;
  }, [currentWeekDay]);

  useEffect(() => {
    if (isOpen && !isSaving && !isCalendar) {
      onClose();
    }
  }, [isSaving]);

  const onChangeTimezone = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeZone(e.target.value);
  };

  const onCloseDrawer = () => {
    if (!isSaving) {
      onClose();
    }
  };

  const onSubmit = values => {
    const availability = WorkingDays.map(day => ({
      day,
      slots: values[day]
        ? values[day].map(slot => ({
            start: slot.start,
            end: slot.end,
          }))
        : [],
      active: values[day]?.length > 0,
      removed: values[day] ? values[day].length === 0 : true,
    }));

    dispatch(
      scheduleActionCreators.updateProviderSchedule({
        providerId,
        data: {
          timezone: selectedTimeZone,
          availability,
        },
        callback: isSucceed => {
          if (isSucceed) onClose();
        },
      }),
    );
  };

  return (
    <Formik
      initialValues={
        workingDays !== undefined
          ? workingDays
          : {
              monday: null,
              tuesday: null,
              wednesday: null,
              thursday: null,
              friday: null,
              saturday: null,
              sunday: null,
            }
      }
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={AvailabilitySchema}
    >
      {({ isValid, values, setFieldValue, handleSubmit, errors }) => {
        const onToggleDay = (day: string) => () => {
          void setFieldValue(day, values[day] ? null : []);
        };

        return (
          <Drawer
            title={title}
            open={isOpen}
            onClose={onCloseDrawer}
            variant={drawerType.FORM}
            submitBtnTitle={isSaving ? 'Saving' : 'Update availability'}
            onSubmit={isValid ? handleSubmit : null}
          >
            <Box className={classes.drawerContent}>
              <Box>
                <Text className={classes.label} weight={fontWeight.BOLD}>
                  Select timezone
                </Text>
                <Select
                  variant={selectType.SECONDARY}
                  value={selectedTimeZone}
                  displayEmpty
                  /* emptyText="Select timezone" */
                  className={classes.select}
                  onChange={onChangeTimezone}
                  options={US_TIMEZONES}
                />
              </Box>
              {weekdays.map(day => (
                <Box key={`day-${day}`} id={`g-av-${day}`} className={classes.daySlots}>
                  <FieldArray
                    name={day}
                    render={arrayHelpers => (
                      <>
                        <TimeHeading
                          label={day}
                          checked={!!values?.[day]}
                          onChange={onToggleDay(day)}
                          onAddTimeSlot={() => {
                            const lastTimeSlot = values?.[day]
                              ? values[day][values[day].length - 1]
                              : { start: '00:00', end: '00:00' };
                            arrayHelpers.insert(
                              values?.[day].length || 0,
                              lastTimeSlot
                                ? {
                                    start: lastTimeSlot.end,
                                    end: lastTimeSlot.end,
                                  }
                                : {
                                    start: '00:00',
                                    end: '00:00',
                                  },
                            );
                          }}
                        />
                        <TextError errorMsg={typeof errors[day] === 'string' ? String(errors[day]) : ''} />

                        {values[day]?.map((_, index) => (
                          <Field key={`slot-${index}`}>
                            {({ field, form, meta }) => (
                              <TimeSlot
                                fieldName={`${day}.${index}`}
                                onRemove={() => {
                                  arrayHelpers.remove(index);
                                }}
                                id={`g-av-${day.toLowerCase()}`}
                                field={field}
                                form={form}
                                meta={meta}
                              />
                            )}
                          </Field>
                        ))}
                      </>
                    )}
                  />
                </Box>
              ))}
            </Box>
          </Drawer>
        );
      }}
    </Formik>
  );
};

export { GeneralAvailability };
