import dayjs from 'dayjs';
import { call, fork, put, select, takeLatest, join, delay } from 'redux-saga/effects';
import { IAction, IPayloadAction } from 'redux/store/types';
import * as MemberService from 'services/member/member.service';
import * as ConversationService from 'services/conversation/conversation.service';
import * as profileService from 'services/profile/profile.service';
import { Task } from 'redux-saga';
import { AVATAR_COLOR_ARRAY, SUPPORTED_AUTHORITIES } from 'constants/CommonConstants';
import { getAuth } from '../auth/selectors';
import {
  FETCH_CHATBOTS,
  FETCH_CHATBOTS_FAILED,
  FETCH_CHATBOTS_SUCCESSFUL,
  conversationActionCreators,
  SENDBIRD_INIT,
  FETCH_CHATS,
  FETCH_CHATS_SUCCESSFUL,
} from '../conversation/actions';
import {
  FETCH_PATIENTS,
  FETCH_PATIENTS_SUCCESSFUL,
  FETCH_PATIENTS_FAILED,
  FETCH_PROFILE_SUCCESSFUL,
  FETCH_PROFILE_FAILED,
  FETCH_PROVIDER_PROFILE,
  FETCH_ADMIN_PROFILE,
  FETCH_DEMOGRAPHIC_DETAILS,
  FETCH_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
  FETCH_DEMOGRAPHIC_DETAILS_FAILED,
  FETCH_USER_GROUPS_SUCCESSFUL,
  FETCH_USER_GROUPS_FAILED,
  FETCH_USER_GROUPS,
  PUT_DEMOGRAPHIC_DETAILS,
  PUT_DEMOGRAPHIC_DETAILS_FAILED,
  PUT_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
  UPDATE_WALLET_BALANCE,
  UPDATE_WALLET_BALANCE_FAILED,
  UPDATE_WALLET_BALANCE_SUCCESSFUL,
  GET_CONNECTIONS,
  CONNECTIONS_FETCHED,
  GET_CONNECTIONS_FAILED,
  GET_CONNECTIONS_BY_STATUS,
  GET_CONNECTIONS_BY_STATUS_FAILED,
  GET_CONNECTIONS_BY_STATUS_SUCCESSFUL,
  FETCH_PROVIDERS,
  FETCH_PROVIDERS_FAILED,
  FETCH_PROVIDERS_SUCCESSFUL,
  FETCH_DOMAIN_TYPES,
  FETCH_DOMAIN_TYPES_SUCCESSFUL,
  FETCH_DOMAIN_TYPES_FAILED,
  profileActionCreators,
  CREATE_ASSOCIATED_TAG,
  CREATE_ASSOCIATED_TAG_FAILED,
  CREATE_ASSOCIATED_TAG_SUCCESSFUL,
  FETCH_LOOKUP_DATA,
  FETCH_LOOKUP_DATA_SUCCESSFUL,
  FETCH_LOOKUP_DATA_FAILED,
  FETCH_PROVIDER_DETAILS,
  FETCH_PROVIDER_DETAILS_SUCCESSFUL,
  FETCH_PROVIDER_DETAILS_FAILED,
  FETCH_GROUPS,
  FETCH_GROUPS_SUCCESSFUL,
  FETCH_GROUPS_FAILED,
  FETCH_DEMOGRAPHIC_METADATA,
  FETCH_DEMOGRAPHIC_METADATA_SUCCESSFUL,
  FETCH_DEMOGRAPHIC_METADATA_FAILED,
  FETCH_MEMBER_INSURANCE_SUCCESSFUL,
  FETCH_MEMBER_INSURANCE_FAILED,
  FETCH_MEMBER_INSURANCE,
  PUT_MEMBER_INSURANCE,
  PUT_MEMBER_INSURANCE_SUCCESSFUL,
  PUT_MEMBER_INSURANCE_FAILED,
  UPDATE_ASSOCIATED_TAG,
  UPDATE_ASSOCIATED_TAG_SUCCESSFUL,
  UPDATE_ASSOCIATED_TAG_FAILED,
  ADD_MEMBER_INSURANCE,
  ADD_MEMBER_INSURANCE_SUCCESSFUL,
  ADD_MEMBER_INSURANCE_FAILED,
  MEMBER_CONNECTIONS_FETCH_FAILED,
  MEMBER_CONNECTIONS_FETCHED,
  PROVIDER_CONNECTIONS_FETCHED,
  PROVIDER_CONNECTIONS_FETCH_FAILED,
  FETCH_MORE_CONNECTIONS,
  SEARCH_CHATS,
  FETCH_CARE_TEAM,
  PUT_CARE_TEAM_LIST,
  UPDATE_CARE_TEAM,
  PUT_DEMOGRAPHIC_DETAILS2,
  PUT_CONTACT_DETAILS,
  PUT_CONTACT_DETAILS_SUCCESS,
  PUT_CONTACT_DETAILS_FAILURE,
  INVITE_MEMBER,
  INVITE_MEMBER_FAILED,
  INVITE_MEMBER_SUCCESSFUL,
  UPDATE_PROVIDER_GENERAL,
  UPDATE_PROVIDER_GENERAL_FAILED,
  UPDATE_PROVIDER_GENERAL_SUCCESSFUL,
  FETCH_TODO,
  FETCH_TODO_SUCCESSFUL,
  FETCH_TODO_FAILED,
  FETCH_EVALUATION_TODO,
  FETCH_EVALUATION_TODO_SUCCESSFUL,
  FETCH_EVALUATION_TODO_FAILED,
  FETCH_NOTES_TODO,
  FETCH_NOTES_TODO_SUCCESSFUL,
  FETCH_NOTES_TODO_FAILED,
  TODO_COMPLETED,
  TODO_COMPLETED_SUCCESSFUL,
  TODO_COMPLETED_FAILED,
  UPDATE_TODO,
  PUT_EMERGENCY_DETAILS,
  PUT_EMERGENCY_DETAILS_SUCCESS,
  PUT_EMERGENCY_DETAILS_FAILURE,
  FETCH_TODO_STATS,
  FETCH_TODO_STATS_SUCCESSFUL,
  FETCH_TODO_STATS_FAILED,
  SHARE_ALFIE_VALIDATION,
  FETCH_VALIDATION_TODO,
  FETCH_VALIDATION_TODO_SUCCESSFUL,
  FETCH_VALIDATION_TODO_FAILED,
  FETCH_ADMIN_VALIDATION_TODO_SUCCESSFUL,
  FETCH_ADMIN_VALIDATION_TODO_FAILED,
  FETCH_ADMIN_VALIDATION_TODO,
  FETCH_ALL_PROVIDERS,
  FETCH_ALL_PROVIDERS_SUCCESSFUL,
  FETCH_ALL_PROVIDERS_FAILED,
  FETCH_SYNCED_GOOGLE_CALENDAR,
  FETCH_SYNCED_GOOGLE_CALENDAR_SUCCESSFUL,
  FETCH_SYNCED_GOOGLE_CALENDAR_FAILED,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS_SUCCESSFUL,
  FETCH_ALL_GOOGLE_CALENDAR_EVENTS_FAILED,
  FETCH_REFERRAL_PARTNERS,
  FETCH_REFERRAL_PARTNERS_SUCCESSFUL,
  FETCH_REFERRAL_PARTNERS_FAILED,
  GET_WALLET_BALANCE,
  GET_WALLET_BALANCE_SUCCESSFUL,
  GET_WALLET_BALANCE_FAILED,
  CARETEAM_CONNECTIONS_FETCHED,
  CARETEAM_CONNECTIONS_FETCH_FAILED,
  CARE_NAVIGATOR_CONNECTIONS_FETCHED,
  CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED,
  ALL_CONNECTIONS_FETCHED,
  ALL_CONNECTIONS_FETCH_FAILED,
  REMOVE_CONNECTIONS,
} from './actions';
import { IGetConnectionByStatusQuery } from './types';
import { showSnackbar } from '../snackbar';

function* providerListHandler(action: IAction) {
  const {
    searchQuery,
    pageNumber,
    pageSize,
    orderBy,
    sortBy,
    requestBody,
    careNavigator = false,
  } = action.payload
    ? action.payload
    : {
        searchQuery: '',
        pageNumber: 1,
        pageSize: 1000,
        orderBy: '',
        careNavigator: false,
        sortBy: [],
        requestBody: {},
      };
  try {
    const { meta } = yield select(getAuth);
    const { data } = yield call(
      MemberService.getProviderAll,
      careNavigator
        ? {
            searchQuery,
            pageNumber: pageNumber - 1,
            pageSize,
            orderBy,
            sortBy,
            careNavigator,
          }
        : {
            searchQuery,
            pageNumber: pageNumber - 1,
            pageSize,
            orderBy,
            sortBy,
          },
      requestBody,
    );
    const providerLogged = data?.providers?.find(item => item.providerId === meta.userId);
    const remainingProviders = data.providers?.filter(item => item.providerId !== meta.userId);
    if (providerLogged) {
      remainingProviders.unshift(providerLogged);
    }
    yield put({
      type: FETCH_PROVIDERS_SUCCESSFUL,
      payload: {
        providers: remainingProviders.map(item => ({ ...item, id: item.providerId })),
        totalProviders: data.totalRecords,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_PROVIDERS_FAILED,
      payload: {
        message: e,
      },
    });
  }
}
function* providerListHandler2() {
  try {
    const { meta } = yield select(getAuth);
    const { data } = yield call(
      MemberService.getProviderAll,
      {
        searchQuery: '',
        pageNumber: 0,
        pageSize: 10000,
        orderBy: '',
        sortBy: '',
      },
      {},
    );
    const providerLogged = data?.providers?.find(item => item.providerId === meta.userId);
    const remainingProviders = data.providers?.filter(item => item.providerId !== meta.userId);
    if (providerLogged) {
      remainingProviders.unshift(providerLogged);
    }
    yield put({
      type: FETCH_ALL_PROVIDERS_SUCCESSFUL,
      payload: {
        providers: remainingProviders.map(item => ({ ...item, id: item.providerId })),
        totalProviders: data.totalRecords,
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_ALL_PROVIDERS_FAILED,
      payload: {
        message: e,
      },
    });
  }
}

function* patientListHandler(action: IAction) {
  const { searchQuery, pageNumber, pageSize, orderBy, sortBy, requestBody } = action.payload
    ? action.payload
    : { searchQuery: '', pageNumber: 1, pageSize: 10, orderBy: '', sortBy: [], requestBody: {} };

  try {
    const response = yield call(
      MemberService.getPatientsList,
      {
        searchQuery,
        pageNumber: pageNumber - 1,
        pageSize,
        orderBy,
        sortBy,
      },
      requestBody,
    );

    if (response.status === 200) {
      const { data } = response;
      const patientsList = data.patientsList.map(member => {
        return {
          member: {
            id: member?.userId,
            nickName: member?.fullName,
            fullName: `${member.firstName || ''} ${member?.lastName || ''}`,
            firstName: member?.firstName,
            lastName: member?.lastName,
            photo: member?.photo,
            uuid: member.uuid,
          },
          id: member?.userId,
          email: member?.email,
          phone: member?.phoneNumber,
          insurance: member?.insurance,
          activeChat: member?.activeChat || false,
          completed: member?.totalCompletedSessions,
          fundsInWallet: member?.fundsInWallet,
          state: member?.state,
          engagement: {
            Status: member?.levelOfEngagementStatus === null ? '—' : member?.levelOfEngagementStatus,
            SubStatus: member?.levelOfEngagementSubStatus === null ? '—' : member?.levelOfEngagementSubStatus,
          },
          dob: member.dob ? dayjs(member.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : null,
          uuid: member.uuid,
          actions: {
            id: member?.userId,
            loeStatus: member?.levelOfEngagementStatus === null ? '—' : member?.levelOfEngagementStatus,
          },
        };
      });

      yield put({
        type: FETCH_PATIENTS_SUCCESSFUL,
        payload: {
          currentPage: pageNumber,
          totalPages: data?.totalPages,
          totalRecords: data?.totalRecords,
          patientsList,
        },
      });
    }
  } catch (e) {
    yield put({
      type: FETCH_PATIENTS_FAILED,
      payload: {
        message: e,
      },
    });
  }
}

function* profileFetcher() {
  const { meta } = yield select(getAuth);
  if (
    meta.authority === SUPPORTED_AUTHORITIES.PRACTITIONER ||
    meta.authority === SUPPORTED_AUTHORITIES.MATCH_MAKER ||
    meta.authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR
  ) {
    try {
      const { data } = yield call(MemberService.getProviderProfile);
      yield put({
        type: FETCH_PROFILE_SUCCESSFUL,
        payload: data,
      });
      yield put({
        type: SENDBIRD_INIT,
      });
    } catch (e) {
      const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
      yield put({
        type: FETCH_PROFILE_FAILED,
        payload: { message },
      });
    }
  }
}

function* demographicDetailsFetcher(action: IAction) {
  try {
    const connectionId = action.payload;

    const { data } = yield call(MemberService.getDemographicDetails, {
      connectionId,
    });

    yield put({
      type: FETCH_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
      payload: data,
    });
    yield put(profileActionCreators.fetchDomainTypes(action.payload));
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_DEMOGRAPHIC_DETAILS_FAILED,
      payload: { message },
    });
  }
}

function* providerDetailsFetcher(action: IAction) {
  try {
    const providerId = action.payload;
    const { data } = yield call(MemberService.getProviderProfileById, { providerId });

    yield put({
      type: FETCH_PROVIDER_DETAILS_SUCCESSFUL,
      payload: data,
    });
    yield put(profileActionCreators.fetchDomainTypes(action.payload));
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PROVIDER_DETAILS_FAILED,
      payload: { message },
    });
  }
}

interface IDemographicDetailsAction extends IAction {
  payload: {
    memberId: string;
    demographicDetails: {
      address1?: string;
      address2?: string;
      city?: string;
      levelOfEngagement?: string;
      dateOfBirth?: string;
      editableFields?: string[];
      electronicCommunication?: string;
      email?: string;
      emergencyContact?: string;
      emergencyPhone?: string;
      firstName?: string;
      hipaa?: string;
      lastName?: string;
      idCardFront?: string;
      idCardBack?: string;
      mobilePhone?: string;
      nickName?: string;
      levelEngagement?: string;
      activeChat?: boolean;
      referralSource?: string;
      notInAcuteWithdrawals?: string;
      notSuicidal?: string;
      sex?: string;
      state?: string;
      terms?: string;
      zip?: string;
      genderPronoun?: string;
      primaryInterest?: string[];
    };
  };
}

function* demographicDetailsEdit(action: IDemographicDetailsAction) {
  try {
    const { memberId, demographicDetails } = action.payload;
    const connection = {
      connectionId: memberId,
    };

    // API only accepts field sex in lower case and return field sex in Upper case
    demographicDetails.sex = demographicDetails.sex
      ? demographicDetails.sex.toLowerCase()
      : demographicDetails.sex;

    // TO DO: API currently only accepts 3 fields to be editable firstName, lastName and sex bellow code will be used in the future
    // We should send to the API only the fields we want to update,

    // cleaning payload removing empty values
    let newObj: any = {};
    // let newObj2 = {};
    Object.entries(demographicDetails).forEach(([k, v]) => {
      if (v != null) {
        if (
          (typeof v === 'string' && v.length > 0) ||
          (Array.isArray(v) && v.length > 0) ||
          typeof v === 'boolean'
        ) {
          newObj[k] = demographicDetails[k];
        }
      }
    });
    newObj = {
      ...newObj,
      referralSources: newObj.referralSources !== undefined ? newObj.referralSources : [],
    };
    /* newObj2 = {
      ...newObj,
      levelOfEngagementSubStatus:
        newObj.levelOfEngagementStatus === 'New member' || newObj.levelOfEngagementStatus === 'Referral'
          ? 'Outreach needed'
          : newObj.levelOfEngagementStatus === 'Not admitted to care' ||
            newObj.levelOfEngagementStatus === 'Ghost' ||
            newObj.levelOfEngagementStatus === 'Discharged to HLOC' ||
            newObj.levelOfEngagementStatus === 'Self Discharge' ||
            newObj.levelOfEngagementStatus === 'Lost to Care' ||
            newObj.levelOfEngagementStatus === 'Successful Discharge'
          ? 'Not engaged'
          : newObj.levelOfEngagementStatus === 'Active'
          ? 'Engaged'
          : newObj.levelOfEngagementStatus === 'Diy'
          ? 'Not Engaged'
          : '-',
    }; */

    yield call(MemberService.updateDemographicDetails, newObj, connection);

    if (demographicDetails.levelOfEngagement) {
      yield call(MemberService.updateLevelOfEngagementUser, {
        ...connection,
        engagementLevelId: demographicDetails.levelOfEngagement,
      });
    }
    // yield call(MemberService.updateChatStatusUser, connection, {
    //   inActiveChat: demographicDetails.activeChat,
    // });

    // reloading demographic data
    const { data } = yield call(MemberService.getDemographicDetails, connection);

    yield put({
      type: PUT_DEMOGRAPHIC_DETAILS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: PUT_DEMOGRAPHIC_DETAILS_FAILED,
      payload: { message },
    });
  }
}

function* updateWalletBalance(action: IAction) {
  try {
    const { amount, memberId } = action.payload;

    const { data } = yield call(MemberService.addBalanceInWallet, {
      amount,
      connectionId: memberId,
    });

    yield put({
      type: UPDATE_WALLET_BALANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_WALLET_BALANCE_FAILED,
      payload: { message },
    });
  }
}

function* getWalletBalanceHandler(action: IAction) {
  try {
    const connectionId = action.payload;

    const { data } = yield call(MemberService.getWalletBalance, connectionId);

    yield put({
      type: GET_WALLET_BALANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: GET_WALLET_BALANCE_FAILED,
      payload: { message },
    });
  }
}

function* userGroupsFetcher(action: IAction) {
  try {
    const userId = action.payload;

    const { data } = yield call(MemberService.getUserGroups, {
      userId,
    });

    yield put({
      type: FETCH_USER_GROUPS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.data.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_USER_GROUPS_FAILED,
      payload: { message },
    });
  }
}

function* adminProfileFetcher(action: IAction) {
  try {
    const { data } = yield call(MemberService.getAdminProfile, { userId: action?.payload });
    yield put({
      type: FETCH_PROFILE_SUCCESSFUL,
      payload: data,
    });
    yield put({
      type: SENDBIRD_INIT,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_PROFILE_FAILED,
      payload: { message },
    });
  }
}

const formatTimeForConnection = (connection: {
  lastMessageTimestamp: string;
  lastMessageTimestampUnix?: number;
  type: string;
  nickname: any;
  name: any;
  userId: any;
  connectionId: any;
}) => {
  if (connection.type === 'PRACTITIONER' || connection.type === 'MATCH_MAKER') {
    connection.nickname = connection.name;
    connection.userId = connection.connectionId;
  }
  return connection;
};

const getTimeFormattedConnections = (connections: any[]) => {
  return connections?.map(connection => {
    return formatTimeForConnection(connection);
  });
};

// type ConnectionType = { name: string };

const addColorCode = (connections: any[]) => {
  if (connections && connections.length > 0) {
    return connections.map((item, index) => {
      if (!item.profilePicture) {
        item.colorCode = AVATAR_COLOR_ARRAY[index % AVATAR_COLOR_ARRAY.length];
      }
      return item;
    });
  }
  return [];
};

function* getNextPageConnections(
  userId: string,
  pageNumber: number,
  type: string,
  successAction: string,
  failureAction: string,
  search?: string,
) {
  try {
    const params: any = { userId, pageNumber, type };
    if (search && search !== '') {
      params.search = search;
    }
    const {
      data: { results, total },
    } = yield call(MemberService.getPaginatedConnections, params);
    const hasMoreRecords = Math.ceil(total / 10) > pageNumber + 1;
    yield put({
      type: successAction,
      payload: {
        connections: getTimeFormattedConnections(addColorCode(results)),
        userId,
        page: pageNumber,
        hasMoreRecords,
      },
    });
    if (hasMoreRecords && pageNumber + 1 <= 2 && (!search || search === '')) {
      yield call(getNextPageConnections, userId, pageNumber + 1, type, successAction, failureAction, search);
    }
  } catch (e) {
    console.warn(e);
    const message = e.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: failureAction,
      payload: { message },
    });
  }
}

function* getPaginatedConnectionsHandler(action: IAction) {
  const { meta } = yield select(getAuth);
  const memberConnectionsRequired =
    meta.authority === SUPPORTED_AUTHORITIES.ADMIN ||
    meta.authority === SUPPORTED_AUTHORITIES.CARE_NAVIGATOR ||
    meta.authority === SUPPORTED_AUTHORITIES.BILLING_SUPPORT;
  let memberTask: Task;
  if (memberConnectionsRequired) {
    memberTask = yield fork(
      getNextPageConnections,
      action.payload.userId,
      0,
      'members',
      MEMBER_CONNECTIONS_FETCHED,
      MEMBER_CONNECTIONS_FETCH_FAILED,
      null,
    );
  }
  const providerTask = yield fork(
    getNextPageConnections,
    action.payload.userId,
    0,
    'providers',
    PROVIDER_CONNECTIONS_FETCHED,
    PROVIDER_CONNECTIONS_FETCH_FAILED,
    null,
  );
  const careTeamTask = yield fork(
    getNextPageConnections,
    action.payload.userId,
    0,
    'care_team',
    CARETEAM_CONNECTIONS_FETCHED,
    CARETEAM_CONNECTIONS_FETCH_FAILED,
    null,
  );
  yield fork(
    getNextPageConnections,
    action.payload.userId,
    0,
    'all',
    ALL_CONNECTIONS_FETCHED,
    ALL_CONNECTIONS_FETCH_FAILED,
    null,
  );
  const careNavigatorTask = yield fork(
    getNextPageConnections,
    action.payload.userId,
    0,
    'care_navigator',
    CARE_NAVIGATOR_CONNECTIONS_FETCHED,
    CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED,
    null,
  );
  const groupsTask = yield fork(userGroupsFetcher, {
    type: FETCH_USER_GROUPS,
    payload: action.payload.userId,
  });
  if (memberConnectionsRequired) {
    yield join([memberTask, providerTask, careTeamTask, careNavigatorTask, groupsTask]);
  } else {
    yield join([providerTask, careTeamTask, careNavigatorTask, groupsTask]);
  }
  yield put({
    type: FETCH_CHATS_SUCCESSFUL,
  });
}

function* fetchMoreConnections(action: IAction) {
  const { userId, type, search } = action.payload;
  let successAction;
  let failureAction;
  let page;
  if (type === 'members') {
    successAction = MEMBER_CONNECTIONS_FETCHED;
    failureAction = MEMBER_CONNECTIONS_FETCH_FAILED;
    page = yield select(state => state.profile.chats.members.currentPage);
  } else if (type === 'providers') {
    successAction = PROVIDER_CONNECTIONS_FETCHED;
    failureAction = PROVIDER_CONNECTIONS_FETCH_FAILED;
    page = yield select(state => state.profile.chats.providers.currentPage);
  } else if (type === 'care_team') {
    successAction = CARETEAM_CONNECTIONS_FETCHED;
    failureAction = CARETEAM_CONNECTIONS_FETCH_FAILED;
    page = yield select(state => state.profile.chats.careTeam.currentPage);
  } else if (type === 'care_navigator') {
    successAction = CARE_NAVIGATOR_CONNECTIONS_FETCHED;
    failureAction = CARE_NAVIGATOR_CONNECTIONS_FETCH_FAILED;
    page = yield select(state => state.profile.chats.careNavigator.currentPage);
  } else if (type === 'all') {
    successAction = ALL_CONNECTIONS_FETCHED;
    failureAction = ALL_CONNECTIONS_FETCH_FAILED;
    page = yield select(state => state.profile.chats.all.currentPage);
  }
  yield call(getNextPageConnections, userId, Number(page) + 1, type, successAction, failureAction, search);
}

function* searchInChats(action: IAction) {
  const { type, search } = action.payload;
  try {
    const {
      data: { results: connections },
    } = yield call(MemberService.getPaginatedConnections, { type, search, pageSize: 30 });
    yield put(profileActionCreators.searchInChatsSuccess({ connections: addColorCode(connections) }));
  } catch (error) {
    console.warn(error);
    yield put(profileActionCreators.searchInChatsFailed(error));
  }
}

function* getConnectionsHandler(action: IAction) {
  try {
    if (!action.payload) {
      return;
    }
    const {
      data: { activeConnections, requestedConnections, pastConnections },
    } = yield call(MemberService.getConnections, action.payload);
    const activeConnections1 = addColorCode(activeConnections);
    const pastConnections1 = addColorCode(pastConnections);

    yield put({
      type: CONNECTIONS_FETCHED,
      payload: {
        activeConnections: getTimeFormattedConnections(activeConnections1),
        pastConnections: getTimeFormattedConnections(pastConnections1),
        requestedConnections: requestedConnections || [],
        userId: action.payload.userId,
      },
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: GET_CONNECTIONS_FAILED,
      payload: { message },
    });
  }
}

function* getConnectionsByStatusHandler(action: IPayloadAction<IGetConnectionByStatusQuery>) {
  try {
    const { data } = yield call(MemberService.getConnectionsByStatus, action.payload);

    yield put({
      type: GET_CONNECTIONS_BY_STATUS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: GET_CONNECTIONS_BY_STATUS_FAILED,
      payload: { message },
    });
  }
}

function* fetchDomainTypes(action: IAction) {
  try {
    const { data } = yield call(MemberService.getDomainTypes, {
      patientId: action.payload,
    });

    yield put({
      type: FETCH_DOMAIN_TYPES_SUCCESSFUL,
      payload: data.types,
    });
    yield put(profileActionCreators.fetchLookupData(action.payload));
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_DOMAIN_TYPES_FAILED,
      payload: { message },
    });
  }
}

function* fetchLookupDataSaga(action: IAction) {
  try {
    const { data } = yield call(MemberService.getDomainLookups, { patientId: action.payload });

    yield put({
      type: FETCH_LOOKUP_DATA_SUCCESSFUL,
      payload: data.lookupMap,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_LOOKUP_DATA_FAILED,
      payload: { message },
    });
  }
}

function* createNewTagHandler(action: IAction) {
  const { data: bodyRequest, callback } = action.payload;
  try {
    yield call(MemberService.createNewAssociatedTag, bodyRequest);
    yield put({ type: CREATE_ASSOCIATED_TAG_SUCCESSFUL });
    yield put(profileActionCreators.fetchDomainTypes(bodyRequest.patientId));
    callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'New tag added successfully',
      }),
    );
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    callback(message);
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: message,
      }),
    );
    yield put({
      type: CREATE_ASSOCIATED_TAG_FAILED,
      payload: { message },
    });
  }
}

function* updateTagHandler(action: IAction) {
  const { data: bodyRequest, associatedTagId, callback } = action.payload;

  try {
    yield call(MemberService.updateAssociatedTag, bodyRequest, { associatedTagId });
    yield put({ type: UPDATE_ASSOCIATED_TAG_SUCCESSFUL });
    yield put(profileActionCreators.fetchDomainTypes(bodyRequest.patientId));
    callback();
    yield put(
      showSnackbar({
        snackType: 'success',
        snackMessage: 'Tag updatedd successfully',
      }),
    );
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_ASSOCIATED_TAG_FAILED,
      payload: { message },
    });
  }
}

/**
 * @Name chatbotsFetcher
 * @param action as IAction
 * @description This method is used to get chatbots from the server using userId
 */
function* chatbotsFetcher(action: IAction) {
  const { pathParams, queryParams } = action.payload;

  try {
    const { data } = yield call(ConversationService.getChatbots, pathParams, queryParams);

    yield put({
      type: FETCH_CHATBOTS_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_CHATBOTS_FAILED,
      payload: { message },
    });
  }
}

function* fetchGroupHandler() {
  try {
    const { data } = yield call(MemberService.getGroups, {
      isPublic: true,
    });

    yield put({
      type: FETCH_GROUPS_SUCCESSFUL,
      payload: {
        groups: data.sort((a, b) => (a.id > b.id ? 1 : -1)),
      },
    });
  } catch (e) {
    yield put({
      type: FETCH_GROUPS_FAILED,
      payload: { message: e.data?.errors?.[0]?.endUserMessage || 'Something went wrong!' },
    });
  }
}

function* demographicMetadataFetcher() {
  try {
    const { data } = yield call(MemberService.getDemographicMetadata);
    yield put({
      type: FETCH_DEMOGRAPHIC_METADATA_SUCCESSFUL,
      payload: {
        demographicMetadata: data,
      },
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_DEMOGRAPHIC_METADATA_FAILED,
      payload: {
        message,
      },
    });
  }
}

function* fetchMemberInsurance(action: IAction) {
  const { memberId } = action.payload;

  try {
    const { data } = yield call(MemberService.getMemberInsuranceInfo, { userId: memberId });
    yield put({
      type: FETCH_MEMBER_INSURANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_MEMBER_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* updateMemberInsurance(action: IAction) {
  const { memberId, payload } = action.payload;
  try {
    yield call(MemberService.updateMemberInsuranceInfo, payload, { userId: memberId });

    const { data } = yield call(MemberService.getMemberInsuranceInfo, { userId: memberId });

    yield put({
      type: PUT_MEMBER_INSURANCE_SUCCESSFUL,
      payload: data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: PUT_MEMBER_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* addMemberInsurance(action: IAction) {
  const { payload } = action.payload;
  try {
    const response = yield call(MemberService.addMemberInsuranceInfo, payload);
    yield put({
      type: ADD_MEMBER_INSURANCE_SUCCESSFUL,
      payload: response,
    });
  } catch (e) {
    yield put(showSnackbar({ snackType: 'error', snackMessage: e?.data?.message }));
    const message = e?.data?.message || 'Something went wrong';
    yield put({
      type: ADD_MEMBER_INSURANCE_FAILED,
      payload: { message },
    });
  }
}

function* fetchCareTeam(action: IAction) {
  try {
    const { userId, scheduleAppointment } = action.payload;
    const { data } = yield call(MemberService.getCareTeamMember, { userId }, { scheduleAppointment });
    yield put({
      type: PUT_CARE_TEAM_LIST,
      payload: data,
    });
  } catch (error) {
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
  }
}

function* updateCareTeam(action: IAction) {
  const { members, userId } = action.payload;
  const connectionId = userId;
  try {
    yield call(MemberService.setCareTeamMemberData, { members }, { userId });
    // yield put(profileActionCreators.fetchDemographicDetails(memberId));
    const { data } = yield call(MemberService.getDemographicDetails, {
      connectionId,
    });
    yield put({
      type: PUT_DEMOGRAPHIC_DETAILS2,
      payload: {
        levelOfEngagementStatus: data?.levelOfEngagementStatus,
        levelOfEngagementSubStatus: data?.levelOfEngagementSubStatus,
        visitFrequency: data?.visitFrequency,
      },
    });
    yield fork(fetchCareTeam, {
      type: FETCH_CARE_TEAM,
      payload: { userId },
    });
  } catch (error) {
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
  }
}

function* updateContactDetails(action: IAction) {
  try {
    const { payload } = action;
    yield call(MemberService.updateContactInfo, payload.contactInfo, {
      id: payload.memberId,
    });

    yield put({
      type: PUT_CONTACT_DETAILS_SUCCESS,
      payload,
    });
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
    yield put({
      type: PUT_CONTACT_DETAILS_FAILURE,
      payload: msg,
    });
  }
}

function* updateEmergencyDetails(action: IAction) {
  try {
    const { payload } = action;
    yield call(MemberService.updateEmergencyInfo, payload.emergencyInfo, {
      id: payload.memberId,
    });

    yield put({
      type: PUT_EMERGENCY_DETAILS_SUCCESS,
      payload: payload.emergencyInfo,
    });
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
    yield put({
      type: PUT_EMERGENCY_DETAILS_FAILURE,
      payload: msg,
    });
  }
}

function* inviteMember(action: IAction) {
  const { memberId } = action.payload;
  try {
    yield call(MemberService.inviteMember, { memberId });
    yield put({
      type: INVITE_MEMBER_SUCCESSFUL,
      payload: { memberId },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: INVITE_MEMBER_FAILED,
      payload: { message },
    });
  }
}

function* updateProviderGeneralInfo(action: IAction) {
  const { id, data, onSuccess } = action.payload;
  try {
    yield call(MemberService.updateProvider, id, data);
    yield put({
      type: UPDATE_PROVIDER_GENERAL_SUCCESSFUL,
      payload: { data },
    });
    if (onSuccess) {
      onSuccess();
    }
    yield put({
      type: FETCH_PROVIDER_DETAILS,
      payload: id,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: UPDATE_PROVIDER_GENERAL_FAILED,
      payload: { message },
    });
  }
}

function* fetchTodo(action: IAction) {
  try {
    const response = yield call(profileService.listTodos, action.payload);
    const { total, results } = response.data;

    yield put({
      type: FETCH_TODO_SUCCESSFUL,
      payload: { results, total },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_TODO_FAILED,
      payload: { message },
    });
  }
}

function* fetchEvaluationTodo(action: IAction) {
  try {
    const response = yield call(profileService.listTodos, action.payload);
    const { total, results } = response.data;

    yield put({
      type: FETCH_EVALUATION_TODO_SUCCESSFUL,
      payload: { results, total },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_EVALUATION_TODO_FAILED,
      payload: { message },
    });
  }
}

function* fetchAdminValidationTodo() {
  try {
    const params = {
      assignedBy: '5fd32cfd70306c6995c4daab',
      type: 'ALFIE Validation',
      pageNumber: 0,
      status: false,
      refresh: true,
    };
    const response = yield call(profileService.listTodos, params);
    const { total, results } = response.data;
    yield put({
      type: FETCH_ADMIN_VALIDATION_TODO_SUCCESSFUL,
      payload: { results, total },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_ADMIN_VALIDATION_TODO_FAILED,
      payload: { message },
    });
  }
}

function* fetchTodoStats(action: IAction) {
  try {
    const response = yield call(profileService.getTodosStats, action.payload);

    yield put({
      type: FETCH_TODO_STATS_SUCCESSFUL,
      payload: response.data,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: message }));
    yield put({
      type: FETCH_TODO_STATS_FAILED,
    });
  }
}

function* fetchNotesTodo(action: IAction) {
  try {
    const response = yield call(profileService.listTodos, action.payload);
    const { total, results } = response.data;

    yield put({
      type: FETCH_NOTES_TODO_SUCCESSFUL,
      payload: { results, total },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_NOTES_TODO_FAILED,
      payload: { message },
    });
  }
}

function* fetchValidationTodo(action: IAction) {
  try {
    const response = yield call(profileService.listTodos, action.payload);
    const { total, results } = response.data;
    yield put({
      type: FETCH_VALIDATION_TODO_SUCCESSFUL,
      payload: { results, total },
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_VALIDATION_TODO_FAILED,
      payload: { message },
    });
  }
}

const createPayload = (assignedTo, type, getAll, status, isAdmin = false) => {
  return {
    assignedTo,
    pageSize: getAll ? 10000 : isAdmin ? 10 : 6,
    pageNumber: 0,
    type,
    status,
    refresh: false,
  };
};

function* todoCompleted(action: IAction) {
  try {
    const { payload } = action;
    const response = yield call(profileService.completeTodos, payload.data);
    const profile = yield select(state => state.profile.profile);
    const adminProfile = yield select(state => state.auth.meta);
    const { isAdmin, userId } = adminProfile;
    let providerId;
    if (isAdmin) {
      providerId = userId;
    } else {
      providerId = profile?.providerId;
    }
    // added delay to show the checkmark
    yield delay(payload.data.status === true ? 1000 : 0);
    if (response.status === 200) {
      yield put(
        showSnackbar({
          snackType: 'success',
          snackMessage:
            payload.data.status === true ? 'Todo completed successfully' : 'Todo marked as incomplete',
        }),
      );
    }
    yield put({
      type: TODO_COMPLETED_SUCCESSFUL,
      payload,
    });
    yield put({
      type: FETCH_TODO,
      payload: createPayload(providerId, 'CHAT', payload.getAll, payload.status),
    });
    yield put({
      type: FETCH_EVALUATION_TODO,
      payload: createPayload(providerId, 'EVALUATION', payload.getAll, payload.status),
    });
    yield put({
      type: FETCH_NOTES_TODO,
      payload: createPayload(providerId, 'NOTES', payload.getAll, payload.status, isAdmin),
    });
    yield put({
      type: FETCH_VALIDATION_TODO,
      payload: createPayload(providerId, 'ALFIE Validation', payload.getAll, payload.status),
    });
    yield put({
      type: FETCH_TODO_STATS,
      payload: {
        assignedBy: '',
        assignedTo: providerId,
      },
    });
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
    yield put({
      type: TODO_COMPLETED_FAILED,
      payload: msg,
    });
  }
}

function* updateTodo(action: IAction) {
  try {
    const { status, data } = yield call(profileService.updateTodo, action.payload.bodyRequest);
    if (status === 200) {
      yield put(showSnackbar({ snackType: 'success', snackMessage: data.successMessage }));
      yield put(conversationActionCreators.fetchAllTodo());
    }
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
  }
}

function* shareAlfieValidation(action: IAction) {
  try {
    yield call(profileService.shareAlfieValidation, action.payload.bodyRequest);
    /* if (status === 200) {
      yield put(showSnackbar({ snackType: 'success', snackMessage: data?.message }));
    } */
  } catch (error) {
    console.warn(error);
    const msg = error.data?.errors?.[0]?.endUserMessage || 'Something went wrong';
    if (msg === 'provider ids not found: []') {
      yield put(
        showSnackbar({
          snackType: 'error',
          snackMessage: 'Please add recipient to share validation.',
        }),
      );
    } else {
      yield put(showSnackbar({ snackType: 'error', snackMessage: msg }));
    }
  }
}

function* fetchSyncedGoogleCalendars(action: IAction) {
  try {
    const { data } = yield call(profileService.getCalendars, { userId: action.payload });
    yield put({
      type: FETCH_SYNCED_GOOGLE_CALENDAR_SUCCESSFUL,
      payload: data?.calenders,
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_SYNCED_GOOGLE_CALENDAR_FAILED,
      payload: { message },
    });
  }
}

function* fetchAllGoogleCalendarEvents(action: IAction) {
  try {
    const { data } = yield call(profileService.getGoogleCalendarEvents, action.payload);

    yield put({
      type: FETCH_ALL_GOOGLE_CALENDAR_EVENTS_SUCCESSFUL,
      payload: data?.data || [],
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_ALL_GOOGLE_CALENDAR_EVENTS_FAILED,
      payload: { message },
    });
  }
}

function* fetchReferralPartners(action: IAction) {
  try {
    const { data } = yield call(MemberService.getReferralPartners, {
      ...action.payload,
      pageNumber: action.payload?.pageNumber - 1 <= 0 ? 0 : action.payload?.pageNumber - 1,
    });

    yield put({
      type: FETCH_REFERRAL_PARTNERS_SUCCESSFUL,
      payload: data || {},
    });
  } catch (e) {
    const message = e?.errors?.[0]?.endUserMessage || 'Something went wrong';
    yield put({
      type: FETCH_REFERRAL_PARTNERS_FAILED,
      payload: { message },
    });
  }
}

function* removeConnections(action: IAction) {
  try {
    const { memberId, providerId } = action.payload;
    yield call(profileService.deleteCareTeamConnection, { userId: providerId }, { secondUserId: memberId });
  } catch (e) {
    yield put(
      showSnackbar({
        snackType: 'error',
        snackMessage: 'Unable to remove connections.',
      }),
    );
  }
}

export default function* profileSaga(): IterableIterator<any> {
  yield takeLatest(FETCH_PROVIDERS, providerListHandler);
  yield takeLatest(FETCH_ALL_PROVIDERS, providerListHandler2);
  yield takeLatest(FETCH_PATIENTS, patientListHandler);
  yield takeLatest(FETCH_PROVIDER_PROFILE, profileFetcher);
  yield takeLatest(FETCH_ADMIN_PROFILE, adminProfileFetcher);
  yield takeLatest(FETCH_DEMOGRAPHIC_DETAILS, demographicDetailsFetcher);
  yield takeLatest(FETCH_PROVIDER_DETAILS, providerDetailsFetcher);
  yield takeLatest(FETCH_USER_GROUPS, userGroupsFetcher);
  yield takeLatest(PUT_DEMOGRAPHIC_DETAILS, demographicDetailsEdit);
  yield takeLatest(UPDATE_WALLET_BALANCE, updateWalletBalance);
  yield takeLatest(GET_WALLET_BALANCE, getWalletBalanceHandler);
  yield takeLatest(FETCH_DOMAIN_TYPES, fetchDomainTypes);
  yield takeLatest(FETCH_LOOKUP_DATA, fetchLookupDataSaga);
  yield takeLatest(GET_CONNECTIONS, getConnectionsHandler);
  yield takeLatest(GET_CONNECTIONS_BY_STATUS, getConnectionsByStatusHandler);
  yield takeLatest(CREATE_ASSOCIATED_TAG, createNewTagHandler);
  yield takeLatest(UPDATE_ASSOCIATED_TAG, updateTagHandler);
  yield takeLatest(FETCH_CHATBOTS, chatbotsFetcher);
  yield takeLatest(FETCH_GROUPS, fetchGroupHandler);
  yield takeLatest(FETCH_DEMOGRAPHIC_METADATA, demographicMetadataFetcher);
  yield takeLatest(FETCH_MEMBER_INSURANCE, fetchMemberInsurance);
  yield takeLatest(PUT_MEMBER_INSURANCE, updateMemberInsurance);
  yield takeLatest(ADD_MEMBER_INSURANCE, addMemberInsurance);
  yield takeLatest(FETCH_MORE_CONNECTIONS, fetchMoreConnections);
  yield takeLatest(SEARCH_CHATS, searchInChats);
  yield takeLatest(FETCH_CARE_TEAM, fetchCareTeam);
  yield takeLatest(UPDATE_CARE_TEAM, updateCareTeam);
  yield takeLatest(INVITE_MEMBER, inviteMember);
  yield takeLatest(PUT_CONTACT_DETAILS, updateContactDetails);
  yield takeLatest(PUT_EMERGENCY_DETAILS, updateEmergencyDetails);
  yield takeLatest(UPDATE_PROVIDER_GENERAL, updateProviderGeneralInfo);
  yield takeLatest(FETCH_TODO, fetchTodo);
  yield takeLatest(FETCH_EVALUATION_TODO, fetchEvaluationTodo);
  yield takeLatest(UPDATE_TODO, updateTodo);
  yield takeLatest(SHARE_ALFIE_VALIDATION, shareAlfieValidation);
  yield takeLatest(FETCH_NOTES_TODO, fetchNotesTodo);
  yield takeLatest(FETCH_VALIDATION_TODO, fetchValidationTodo);
  yield takeLatest(FETCH_CHATS, getPaginatedConnectionsHandler);
  yield takeLatest(TODO_COMPLETED, todoCompleted);
  yield takeLatest(FETCH_TODO_STATS, fetchTodoStats);
  yield takeLatest(FETCH_SYNCED_GOOGLE_CALENDAR, fetchSyncedGoogleCalendars);
  yield takeLatest(FETCH_ALL_GOOGLE_CALENDAR_EVENTS, fetchAllGoogleCalendarEvents);
  yield takeLatest(FETCH_ADMIN_VALIDATION_TODO, fetchAdminValidationTodo);
  yield takeLatest(FETCH_REFERRAL_PARTNERS, fetchReferralPartners);
  yield takeLatest(REMOVE_CONNECTIONS, removeConnections);
}
