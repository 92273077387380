import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

// components
import { Stack } from '@mui/material';
import { fontWeight, Heading, headingLevel } from '@confidant-health/lib/ui/atoms/typography';
import { Badge, badgeStyle, badgeType } from '@confidant-health/lib/ui/atoms/badge';
import { Link } from '@confidant-health/lib/ui/atoms/link';
import { Avatar, avatarType } from '@confidant-health/lib/ui/atoms/avatar';
import { Filter, Table, tableParamsType } from '@confidant-health/lib/ui/organisms/table';
import { Menu } from '@confidant-health/lib/ui/molecules/menu';
import { AppointmentStatus, AppointmentType } from '@confidant-health/lib/constants/CommonConstants';
import { BaseLayout } from 'layouts/base';
import { iconBtnType, IconButton } from '@confidant-health/lib/ui/molecules/icon-button';
// redux
import { getProfile } from 'redux/modules/profile/selectors';
import { profileActionCreators } from 'redux/modules/profile/actions';
import history from 'utils/history';
import dayjs, { getUserTimeZone } from 'utils/dayjs';
import AddSchedule, { INewSchedulePayload } from 'pages/admin/appointments/add-schedule';
import { appointmentActionCreators } from 'redux/modules/appointment';
import { selectProviderSchedule } from 'redux/modules/schedule/selectors';
import { getAppointment } from 'redux/modules/appointment/selectors';
import { getAuth } from 'redux/modules/auth/selectors';
import { sentenceCase } from 'sentence-case';
import { IDemographicDetails } from 'redux/modules/profile/types';

// mock
import { stateCodes } from 'pages/admin/member-list/MemberList.constants';
import { memberColumns, filterOptionsMock, multiSelectFilterOptionsMock } from './MemberList.mock';

// styles
import { useStyles } from './MemberList.styles';
import { appointmentSorterMap } from '../appointments/AppointmentList.constants';
import { AddMemberDrawer } from '../../admin/member-list/add-member-drawer/AddMemberDrawer';
import PriorityEmpty from '../member-detail/components/PriorityEmpty';
import UpdateLevelOfEngagement from '../sessions/sign-off-v2/UpdateLevelOfEngagement';
import UpdateVisitFrequency from '../member-detail/components/CareTeam/edit';

const MemberList: FC = () => {
  const [days] = useState(7);
  const [selectedDateTime] = useState(null);
  const providerSchedule = useSelector(selectProviderSchedule);
  const { isAdmin, meta } = useSelector(getAuth);
  const [showAddSchedule, setShowAddSchedule] = useState(false);
  const [showCalendar] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [openLevelOfEngagement, setOpenLevelOfEngagement] = useState(false);
  const [addMemberDrawerOpen, setAddMemberDrawerOpen] = useState(false);
  const [openVisitFrequency, setOpenVisitFrequency] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const { isRequesting } = useSelector(getAppointment);
  const [frequencyDataObj, setFrequencyDataObj] = useState({
    userId: '',
    name: '',
    visitFrequency: '',
  });
  const { appointmentType } = useParams();
  const [value, setValue] = useState<tableParamsType>({
    search: { searchKey: '' },
    pagination: { currentPage: 1, rowsPerPage: 10 },
    sorter: { direction: 'asc', column: '' },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    isLoading,
    payload: { patientsList, totalRecords },
    demographicDetails = {} as IDemographicDetails,
  } = useSelector(getProfile);
  const { profile } = useSelector(getProfile);

  // const roles = useSelector(selectProviderRoles);
  const [membersFiltered, setMembersFiltered] = useState(patientsList);
  const [selectedFilter] = useState([]);

  const handleFetchPatientsParamsFormat = ({ search, pagination, sorter }: tableParamsType) => {
    const sortBy = sorter.column === 'member' ? 'name' : sorter.column || '';
    const params = {
      searchQuery: search.searchKey,
      pageNumber: pagination.currentPage,
      pageSize: pagination.rowsPerPage,
      orderBy: sorter.direction,
      sortBy,
    };

    return params;
  };

  const onCloseAddSchedule = () => {
    // setSelectedDateTime(null);
    setShowAddSchedule(false);
  };

  const generateParams = ({ pagination, sorter }: tableParamsType) => {
    const isPending = appointmentType === 'pending' || appointmentType === 'past';
    const isPast = appointmentType === 'past';
    const sortBy = isPast
      ? appointmentSorterMap.appointmentTime
      : appointmentSorterMap[sorter.column] || sorter.column || '';
    const pageNumber = isPending ? 1 : pagination.currentPage;
    const pageSize = isPending ? 1000 : pagination.rowsPerPage;
    const orderBy = isPast ? 'desc' : sorter.direction;

    const queryParams = { orderBy, pageNumber, pageSize, sortBy };
    return queryParams;
  };

  const generateFilters = () => {
    const filters = [];

    if (appointmentType === AppointmentType.CURRENT) {
      filters.push({ searchField: 'status', searchQuery: AppointmentStatus.BOOKED });
    }

    if (showCalendar) {
      filters.push({
        searchField: 'startTime',
        // startTime: e.g. '26-10-2022 00:00:00'
        searchQuery:
          appointmentType === AppointmentType.PAST
            ? dayjs().subtract(3, 'month').format('DD-MM-YYYY HH:mm:ss')
            : dayjs().format('DD-MM-YYYY HH:mm:ss'),
        // endTime: e.g. '26-11-2022 00:00:00'
        searchMeta:
          appointmentType === AppointmentType.PAST
            ? dayjs().format('DD-MM-YYYY HH:mm:ss')
            : dayjs().add(3, 'month').format('DD-MM-YYYY HH:mm:ss'),
      });
    }

    return filters;
  };

  const fetchAppointments = () => {
    const filters = generateFilters();

    let type: string = appointmentType;
    if (appointmentType === AppointmentType.PENDING) {
      type = 'current_draft';
    }
    const size = appointmentType !== AppointmentType.PENDING && showCalendar ? 90 : days;

    const bodyRequest = {
      providerId: profile.providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: providerSchedule?.timezone || getUserTimeZone(),
      type,
      textSearch: value.search.searchKey,
      size,
      filters,
    };
    const queryParams = generateParams(value);
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const fetchAppointments2 = (providerId, timeZone) => {
    const filters = [{ searchField: 'status', searchQuery: 'BOOKED' }];
    const queryParams = {
      orderBy: 'desc',
      pageNumber: 0,
      size: 180,
      sortBy: 'startTime',
      statuses: 'BOOKED',
      type: 'CURRENT',
      searchQuery: '',
    };
    const bodyRequest = {
      providerId,
      refDate: dayjs().format('DD-MM-YYYY'),
      timezone: timeZone || getUserTimeZone(),
      type: 'current',
      textSearch: '',
      size: 180,
      filters,
    };
    dispatch(
      appointmentActionCreators.fetchAppointments({
        bodyRequest,
        queryParams: { ...queryParams },
      }),
    );
  };

  const requestAppointment = (payload: INewSchedulePayload) => {
    dispatch(
      appointmentActionCreators.createAppointment({
        data: { ...payload, timezone: providerSchedule?.timezone },
        callback: (isSucceed: boolean, isInstantSession: boolean, appointmentId: string) => {
          if (isSucceed) {
            onCloseAddSchedule();
            fetchAppointments();
            if (!isAdmin) {
              fetchAppointments2(profile.providerId, providerSchedule.timezone);
            }
            if (isInstantSession) {
              history.push(`/provider/appointments/current/${appointmentId}/session`);
            }
          }
        },
      }),
    );
  };

  const getPatientListMultiSelectRequestBody = () => {
    const multiselectFilters = value.search?.multiSelectFilter;
    const states = multiselectFilters?.State ?? [];
    const designation = [];
    const levelOfEngagements = [];
    const activeInChat = true;
    const session = multiselectFilters?.['Sessions Attended']
      ? getSessionValue(multiselectFilters?.['Sessions Attended'])
      : '';
    const levelOfEngagementStatus = [];
    const levelOfEngagementSubStatus = [];
    if (multiselectFilters?.Outreach) {
      levelOfEngagementSubStatus.push('Outreach needed');
    }
    const vbcFlag = multiselectFilters?.VBC?.length > 0 || false;
    if (multiselectFilters?.['Level of Engagement']) {
      multiselectFilters['Level of Engagement'].forEach(filter => {
        switch (filter) {
          case 'Engaged':
            levelOfEngagementSubStatus.push('Engaged');
            break;
          case 'Not engaged':
            levelOfEngagementSubStatus.push('Not engaged');
            break;
          case 'New member':
            levelOfEngagementStatus.push('New member');
            break;
          case 'Referral':
            levelOfEngagementStatus.push('Referral');
            break;
          case 'Active':
            levelOfEngagementStatus.push('Active');
            break;
          case 'Self Discharge':
            levelOfEngagementStatus.push('Self Discharge');
            break;
          case 'Successful Discharge':
            levelOfEngagementStatus.push('Successful Discharge');
            break;
          case 'Discharged to HLOC':
            levelOfEngagementStatus.push('Discharged to HLOC');
            break;
          case 'Lost to Care':
            levelOfEngagementStatus.push('Lost to Care');
            break;
          case 'Not admitted to care':
            levelOfEngagementStatus.push('Not admitted to care');
            break;
          case 'Diy':
            levelOfEngagementStatus.push('Diy');
            break;
          case 'Ghost':
            levelOfEngagementStatus.push('Ghost');
            break;
          case 'Invite pending':
            levelOfEngagementStatus.push('Invite pending');
            break;
          case 'Invited':
            levelOfEngagementStatus.push('Invited');
            break;
          default:
            break;
        }
      });
    }

    if (value?.search?.dateFilter?.startDate && value?.search?.dateFilter?.endDate) {
      return {
        activeInChat,
        designation,
        levelOfEngagements,
        searchQuery: value?.search?.searchKey || '',
        session,
        states,
        vbcFlag,
        startDate: value?.search?.dateFilter?.startDate
          ? dayjs(value?.search?.dateFilter?.startDate).format('YYYY-MM-DD')
          : '',
        endDate: value?.search?.dateFilter?.endDate
          ? dayjs(value?.search?.dateFilter?.endDate).format('YYYY-MM-DD')
          : '',
        levelOfEngagementStatus,
        levelOfEngagementSubStatus,
      };
    }
    return {
      activeInChat,
      designation,
      levelOfEngagements,
      searchQuery: value?.search?.searchKey || '',
      session,
      states,
      startDate: '',
      endDate: '',
      vbcFlag,
      levelOfEngagementStatus,
      levelOfEngagementSubStatus,
    };
  };

  const getSessionValue = (valueArray: string[]) => {
    switch (valueArray[0]) {
      case '2 Sessions':
        return 'AT_LEAST_TWO_SESSION';
      case 'No Sessions':
        return 'NO_SESSION';
      default:
        return '';
    }
  };

  const onSubmitNewSchedule = (payload: INewSchedulePayload) => {
    requestAppointment(payload);
  };

  const OpenAddMemberDrawer = () => {
    setAddMemberDrawerOpen(true);
  };

  const closeAddMemberDrawer = () => {
    setAddMemberDrawerOpen(false);
  };

  const closeDrawerAndRefreshMemberList = () => {
    setAddMemberDrawerOpen(false);
    dispatch(
      profileActionCreators.fetchPatients({
        ...handleFetchPatientsParamsFormat(value),
        requestBody: value.search?.multiSelectFilter ? getPatientListMultiSelectRequestBody() : {},
      }),
    );
  };

  const onRowClick = item => {
    history.push(`/provider/members/${item.member.id}?name=dashboard`);
  };

  const onViewProfile = userId => () => {
    onRowClick({ member: { id: userId } });
  };

  const onUpdateLoe = (memberId: string) => () => {
    // history.push(`/admin/members/${memberId}?name=general-information`);
    setIsTableLoading(false);
    dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    setTimeout(() => {
      setSelectedMemberId(memberId);
      setOpenLevelOfEngagement(true);
    }, 2000);
  };

  const onupdateVisitFrequency = (memberId: string) => () => {
    // history.push(`/admin/members/${memberId}?name=general-information`);
    setIsTableLoading(false);
    setSelectedMemberId(memberId);
    dispatch(profileActionCreators.fetchDemographicDetails(memberId));
    setTimeout(() => {
      setFrequencyDataObj({
        userId: meta.userId,
        name: meta.nickName,
        visitFrequency: demographicDetails.visitFrequency,
      });
      setOpenVisitFrequency(true);
      setIsTableLoading(true);
    }, 2000);
  };

  const onViewChat = userId => () => {
    history.push(`/provider/chats?userId=${userId}&type=care_team`);
  };

  const onScheduleAppointment = userId => () => {
    setSelectedMemberId(userId);
    setShowAddSchedule(true);
  };

  const inviteMember = (memberId: string) => () => {
    dispatch(profileActionCreators.inviteMember({ memberId }));
  };

  const renderColumns = memberColumns.map(column => {
    if (column.id === 'member') {
      return {
        ...column,
        renderCell: ({ photo, id, nickName, firstName, lastName, uuid }: any) => {
          return (
            <Link className={classes.memberColumn} to={`/provider/members/${id}?name=dashboard`}>
              <div className={classes.member}>
                <Avatar
                  size={40}
                  variant={avatarType.CIRCLE}
                  src={photo}
                  name={
                    firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'
                  }
                />
                <div className={classes.info}>
                  <span className={classes.name}>
                    {firstName && lastName ? `${firstName || ''} ${lastName || ''}` : nickName || 'No Name'}
                  </span>
                  <span className={classes.id}>{uuid || 'UID'}</span>
                </div>
              </div>
            </Link>
          );
        },
      };
    }
    if (column.id === 'engagement') {
      return {
        ...column,
        renderCell: (engagement: { Status: string; SubStatus: string }) => {
          return (
            <>
              {engagement?.Status !== '—' ? (
                <Badge
                  variant={badgeType.FILLED}
                  className={`${classes.engagement} ${classes.centerDiv} ${
                    engagement?.SubStatus === 'Engaged'
                      ? classes.engaged
                      : engagement?.SubStatus === 'Outreach needed'
                      ? classes.outreachNeeded
                      : classes.notEngaged
                  }`}
                >
                  {sentenceCase(engagement?.Status || '')}
                </Badge>
              ) : (
                <div className={classes.centerDiv}>{engagement?.Status}</div>
              )}
            </>
          );
        },
      };
    }
    if (column.id === 'state') {
      return {
        ...column,
        renderCell: (state: string) => <span className={classes.centerDiv}>{stateCodes[state]}</span>,
      };
    }
    if (column.id === 'completed') {
      return {
        ...column,
        renderCell: (completed: number) => `${completed} sessions`,
      };
    }
    if (column.id === 'isActiveChat') {
      return {
        ...column,
        renderCell: (isActiveChat = false) => {
          return (
            <Badge
              variant={badgeType.OUTLINED}
              style={isActiveChat ? badgeStyle.RESOLVED : badgeStyle.UNRELATED}
              className={classes.status}
            >
              <span className={clsx(classes.dot, { [classes.dotActive]: isActiveChat })} />{' '}
              {isActiveChat ? 'Active' : 'Inactive'}
            </Badge>
          );
        },
      };
    }
    if (column.id === 'actions') {
      return {
        ...column,
        renderCell: ({ id: memberId, loeStatus }) => {
          const items = [
            { label: 'View Profile', onClick: onViewProfile(memberId) },
            { label: 'Go to chat', onClick: onViewChat(memberId) },
            { label: 'Schedule Appointment', onClick: onScheduleAppointment(memberId) },
            { label: 'Update LOE', onClick: onUpdateLoe(memberId) },
            { label: 'Update visit frequency', onClick: onupdateVisitFrequency(memberId) },
          ];
          if (loeStatus === 'Invite pending') {
            items.push({ label: 'Send invite', onClick: inviteMember(memberId) });
          }
          if (loeStatus === 'Invited') {
            items.push({ label: 'Resend invite', onClick: inviteMember(memberId) });
          }
          return (
            <Menu
              icon="more"
              className={classes.menu}
              itemsWrapperClassName={classes.menuItemsWrapper}
              items={items}
            />
          );
        },
      };
    }
    if (column.id === 'phone') {
      return {
        ...column,
        renderCell: (phone: string) => {
          if (phone) {
            const last10Digits = phone.slice(-10);
            // Format the number as (XXX) XXX-XXXX
            const formattedNumber = `(${last10Digits.slice(0, 3)}) ${last10Digits.slice(
              3,
              6,
            )}-${last10Digits.slice(6)}`;

            return <span className={classes.centerDiv}>{formattedNumber}</span>;
          }
          return <span className={classes.centerDiv}>—</span>;
        },
      };
    }
    return column;
  });

  useEffect(() => {
    dispatch(
      profileActionCreators.fetchPatients({
        ...handleFetchPatientsParamsFormat(value),
        requestBody: value.search?.multiSelectFilter
          ? getPatientListMultiSelectRequestBody()
          : {
              activeInChat: true,
              designation: [],
              endDate: '',
              levelOfEngagementStatus: [],
              levelOfEngagementSubStatus: [],
              levelOfEngagements: [],
              searchQuery: value?.search?.searchKey || '',
              session: '',
              startDate: '',
              states: [],
              vbcFlag: false,
            },
      }),
    );
  }, [value]);

  useEffect(() => {
    const newList =
      selectedFilter.length > 0
        ? patientsList.filter(item => {
            let isMatch = true;
            if (selectedFilter.includes('active_chat')) {
              if (!item.isActiveChat) {
                isMatch = false;
              }
            } else if (selectedFilter.includes('inactive_chat')) {
              if (item.isActiveChat) {
                isMatch = false;
              }
            }
            if (isMatch) {
              if (selectedFilter.includes('2_sessions')) {
                isMatch = item.completed >= 2;
              } else if (selectedFilter.includes('no_sessions')) {
                isMatch = item.completed === 0;
              }
            }
            return isMatch;
          })
        : patientsList;
    setMembersFiltered(newList);
  }, [patientsList, selectedFilter]);
  return (
    <BaseLayout>
      <AddMemberDrawer
        open={addMemberDrawerOpen}
        onClose={closeAddMemberDrawer}
        closeAndRefresh={closeDrawerAndRefreshMemberList}
        isAdmin={false}
      />
      {showAddSchedule && (
        <AddSchedule
          isOpen={showAddSchedule}
          onClose={onCloseAddSchedule}
          selectedDateTime={selectedDateTime}
          onSubmit={onSubmitNewSchedule}
          isSaving={isRequesting}
          memberId={selectedMemberId}
          isMemberList
        />
      )}
      <UpdateLevelOfEngagement
        openDrawer={openLevelOfEngagement}
        onClose={() => setOpenLevelOfEngagement(false)}
        memberId={selectedMemberId}
        onUpdated={() => {
          setTimeout(() => {
            dispatch(
              profileActionCreators.fetchPatients({
                ...handleFetchPatientsParamsFormat(value),
                requestBody: getPatientListMultiSelectRequestBody() || {},
              }),
            );
            setIsTableLoading(true);
          }, 2000);
        }}
      />
      {frequencyDataObj.userId.trim() !== '' && (
        <UpdateVisitFrequency
          openDrawer={openVisitFrequency}
          onClose={() => setOpenVisitFrequency(false)}
          data={frequencyDataObj}
          currentUserId={selectedMemberId}
        />
      )}
      <div className={classes.root}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="center" gap={2}>
            <Heading className={classes.heading} level={headingLevel.XL} weight={fontWeight.BOLD}>
              Members
            </Heading>
            <Badge
              className={classes.totalMemberBadge}
              variant={badgeType.OUTLINED}
              style={badgeStyle.UNRELATED}
            >
              {totalRecords} total
            </Badge>
          </Stack>

          <IconButton
            className={classes.addMemberBtn}
            icon="plus"
            variant={iconBtnType.PRIMARY}
            onClick={OpenAddMemberDrawer}
          >
            Add member
          </IconButton>
        </Stack>
        <Table
          searchProps={{
            placeholder: 'Search patients by name, phone, or email',
            filterProps: {
              variant: Filter.tableFilterType.MULTIPLE,
              options: filterOptionsMock,
              // allOptionLabel: 'All member',
              // value: selectedFilter,
              // onChange: onChangeFilter,
              multiSelectOptions: multiSelectFilterOptionsMock,
              dateFilter: {
                startDate: '',
                endDate: '',
              },
            },
          }}
          gridProps={{
            columns: renderColumns,
            data: membersFiltered,
            isLoading: isTableLoading && isLoading,
            onRowClick,
            emptyState: (
              <PriorityEmpty
                title="No members found"
                description=""
                className={classes.subHeading}
                glyph="avatar-icon-search"
              />
            ),
          }}
          paginationProps={{
            currentRows: membersFiltered?.length,
            totalCount: totalRecords,
          }}
          value={value}
          onChange={(newValue: any) => {
            const newVal = newValue.search.filter?.filter(
              fil =>
                fil !== 'active_chat' &&
                fil !== 'inactive_chat' &&
                fil !== '2_sessions' &&
                fil !== 'no_sessions',
            );
            const oldVal = value.search.filter;
            if (
              newValue.search.filter?.includes('active_chat') &&
              newValue.search.filter?.includes('inactive_chat')
            ) {
              if (oldVal?.includes('active_chat')) {
                newVal.push('inactive_chat');
              } else if (oldVal?.includes('inactive_chat')) {
                newVal.push('active_chat');
              }
            } else if (
              newValue.search.filter?.includes('active_chat') ||
              newValue.search.filter?.includes('inactive_chat')
            ) {
              newVal.push(newValue.search.filter?.find(i => i === 'active_chat' || i === 'inactive_chat'));
            }
            if (
              newValue.search.filter?.includes('2_sessions') &&
              newValue.search.filter?.includes('no_sessions')
            ) {
              if (oldVal?.includes('2_sessions')) {
                newVal.push('no_sessions');
              } else if (oldVal?.includes('no_sessions')) {
                newVal.push('2_sessions');
              }
            } else if (
              newValue.search.filter?.includes('2_sessions') ||
              newValue.search.filter?.includes('no_sessions')
            ) {
              newVal.push(newValue.search.filter?.find(i => i === '2_sessions' || i === 'no_sessions'));
            }
            newValue.search.filter = newVal;
            setValue({
              ...newValue,
              search: {
                ...newValue.search,
                filter: newVal?.filter((item, pos) => newVal?.indexOf(item) === pos),
              },
            });
          }}
          className={classes.table}
        />
      </div>
    </BaseLayout>
  );
};

export { MemberList };
